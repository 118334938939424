   
$navbar-height:100px;
.header-fixed {
    .navbar-secondary:last-child{
        border-left:none;
    }
}
.navbar-secondary {
    font-size: 1rem;
    display: flex;
    align-items: center;
    background-color: $misoBlue;
    color: $misoDarkBlue;
    height: 50px;

    .dropdown-menu {
        padding: 10px;
    }

    .dropdown-toggle:after {
        display: none;
    }

    .dropdown {
        &:first-child {
            border-left: 1px solid white;
        }

        .dropdown-item:hover,
        .dropdown-item:focus,
        .dropdown-item:active {
            color: white;
        }

        .profile-dropdown {
            box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 25%);
        }
    }
}

.navbar-secondary-section {
    display: flex;
    justify-content: end;
    color: white;

    > a.navbar-secondary-link {
        margin: 0px 15px 0px 0px;
    }
}


.navbar-secondary-link {
    margin: 0px 0px 0px 15px;
    color: white;
    text-decoration: none;

    &:hover {
        color: white;
        text-decoration: underline;
    }

    &:last-child {
        padding-left: 10px;
        border-left: 1px solid white;
    }
}
@media (max-width: 991px) {
    .navbar-collapse {
        box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.25);
    }
}
.navbar-primary {
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0px;

    .navbar-nav {
        margin-top: 15px;

        & > .nav-item-primary {
            & > .nav-link {
                padding-top: 0;
                height: 100%;
                color: $misoDarkBlue;
                padding-bottom: 10px;
            }
        }

        > .secondary-level > .nav-link {
            font-weight: bold;
        }
    }

    .navbar-brand {
        margin-right: 30px;
        align-content: center;
        display: flex;
        flex-wrap: wrap;
        height: $navbar-height;
    }

    .dropdown-menu {
        border: 0;
    }

    .dropdown-menu.show {
        left: 0px;
        right: 0px;
    }

    .dropdown-toggle {
        color: $misoDarkBlue;

        &:after {
            content: "";
            border-left: none;
            border-right: none;
            margin-left: 0px;
            position: absolute;
        }
    }

    .dropdown-submenu {
        break-after: avoid-column;
    }

    .secondary-level:has(+ .dropdown-submenu) {
        break-after: avoid-page;
    }

    .secondary-level {
        //break-after: avoid-column;
    }

    .secondary-level, .dropdown-submenu {
        min-width: 215px;
        page-break-inside: avoid;
    }

    .secondary-level > .nav-link {
        color: $misoLinkBlue;
        text-decoration: none;
        white-space: normal;
        font-weight: normal;
        padding: 5px 10px;
        margin-top: 0px;

        &:hover {
            text-decoration: underline;
        }

        &:after {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .navbar-nav > .nav-item-primary > .nav-link {
            height: 100%;
        }

        .dropdown {
            display: contents;
            height: 100%;
        }


        .dropdown-menu-list {
            column-width: 215px;
            column-count: auto;
            column-rule: 1px solid $misoDividerGrey;
            max-height: 700px;
            display: block;
        }

        .dropdown-menu {
            padding: 0px 6px 6px;
            margin-top: 0px;
            // margin-left: 5px;
            // width: 100% !important;
        }
    }

    @media (max-width: 991px) {
        .dropdown-toggle {
            &:after {
                border: none !important;
            }
        }

        .navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
            text-decoration: underline;
        }

        .navbar-nav .nav-link:hover:after, .navbar-nav .nav-link:focus:after {
            border-top: none;
        }

        .navbar-nav > .nav-item-primary {
            text-align: left;
        }
    }

    @media (min-width: 992px) {
        .react-area {
            display: block;
        }

        .navbar-nav {
            margin-top: 0px;
            height: $navbar-height;
            align-items: center;
            max-width: 700px;
        }

        .navbar-nav > .nav-item-primary {
            text-align: center;
            flex: 1 1 max-content;
        }

        .navbar-nav > .nav-item-primary > .nav-link {
            line-height: $navbar-height;
            padding-bottom: 0px;
            height: 100%;
            color: $misoPrimaryBlack;
        }

        .dropdown-menu.show {
            border-radius: 0 0 20px 20px;
            box-shadow: inset 0px 4px 3px -3px rgb(0 0 0 / 25%), 0px 3px 6px 0px rgb(0 0 0 / 25%);
            padding: 15px 30px;
            margin-right: auto;
            margin-left: auto;
            display: flex;
            justify-content: center;
        }

        .react-area {
            padding-left: 20px;
            margin-left: 20px;
            padding-right: 10px;
            max-width: 400px;
        }
    }

    @media (min-width: 1200px) {
        .dropdown {
            display: block;
        }

        .dropdown-menu.show {
            width: fit-content;
            left: 0px;
            right: auto;
        }

        .dropdown-menu-end.show {
            left: auto;
            right: 0px;
        }
    }
}


.form-control-search {
    border-color: $misoDividerGrey !important;
}

.btn-search {
    border-color: $misoDividerGrey;
    color: $misoGreen;
}

.btn-search-mobile {
    display: block;
    color: $misoDarkBlue;

    @media (min-width: 992px) {
        display: none;
    }
}

.navbar-toggler {
    color: $misoDarkBlue;
}

.navbar-brand-img {
    height:58px;
}

.dropdown-submenu-title {
    color: $misoPrimaryBlack;
    padding: 8px 0px 5px 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
}

.nav-link--alt {
    color: $misoBlue;
    text-decoration: none;

    &:hover {
        color: $misoBlue;
        text-decoration: none;
    }

    @media (min-width: 768px) {
        display: none;
    }
}
.dropdown-menu-list {
    list-style: none;
    padding-left: 0px;
    display:contents;
}

.react-area {
    border-left: 1px solid $misoDividerGrey;
    padding-left: 10px;
    display: none;

    .block {
        padding-left: 0px;
        padding-right: 0px;
    }
    img {
        max-width:100%;
        height:auto;
    }
    .snapshotdashboardblock {
        .outlook-container-title {
            text-transform: uppercase;
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            display: inline-grid;
            padding-bottom: 10px;

            .outlook-title {
                border: none;
                padding: 0px;
            }

            .update-time {
                font-weight: 400;
                font-family: $misoDefaultFont;
                float: left;
                border: none;
                padding: 0px;
            }
        }

        .snapShots {
            display: block;
        }

        .outlook-box {
            width: 100%;
            margin-bottom:10px;
            .box {
                border-radius: 4px;
            }

            .forecast {
                border: 2px solid $misoBlue;
            }

            .current {
                border: 2px solid $misoLightBlue;
            }

            .lmp {
                border: 2px solid $misoGreen;
            }

            .nsi {
                border: 2px solid $misoDarkGreen;
            }

            .not-defined {
                border: 2px solid $misoGray;
            }
        }
    }

    .eventlistblock {
        min-width: 300px;

        .tab-content-block {
            display: none;
        }

        .meetingItemURL{
            font-weight:400;
        }
        .headerBlock {
            border: none;
            padding-left: 0px;
        }
    }
}
