
/* --------- MISO BASE COLORS ---------------*/


$misoBackgroundBlue: #d9edf7;
$scta_LightBlue: #EDF3F4;
$misoLightBlue: #3db5e6;
$misoBlue: #0082ca;
$misoLighterDarkBlue: #0a4bb2;
$misoDarkBlue: #004d71;
$darkBlueHover: #042C3F;
$misoLinkBlue: #0076D6;

$misoDisabledBlue: #E6F3FA;

$misoVeryLightGreen: #E5F2D9;
$misoLightGreen: #a5d07a;
$misoGreen: #7fbc42;
$misoDarkGreen: #487629;
$misoGreen2: #80bc00;
$misoOpacityDarkGreen2: rgba(72, 118, 41, 0.75);
$scta_LightGreen: #EDF2EA;

$misoTeal: #54C0E8;
$misoLightTeal: #B6DCE1;

$misoLightGray: #9cafac;
$misoLightGray2: #FAFAFA;
$misoGray: #7f8f90;
$misoEvilGray: #666;
$misoBorderGray:#cdd7d8;
$misoBorderMedGray: #bbbbbb;

$misoLightRed2: #FEF6F5;
$misoRed: #b32820;
$misoRed2: #E74F3D;
$misoDarkRed2: #691c32;
$misoOpacityDarkRed: rgba(105, 28, 50, 0.75);
$misoRed3: #b2292e;
$misoLightRed3: #c9696c;

$misoOrange: #f79646;
$misoBorderOrange: #ffb16e;
$misoOrange2: #ff9e18;
$misoLightOrange2: #ffc844;
$misoDarkOrange2: #ee7623;
//here joe adding orange color from styling guide
$misoLightOrange3: #fbba66;
$scta_LightOrange: rgba(238,118,35,0.1);

$misoNearWhite: #e5e5e5;
$misoNearerWhite: #f5f5f5;


$misoPurple: #b1a0c7;
$misoDarkPurple: #77226c;


$misoSHissueF: #00567D;
$misoSHissueECD:#1782C5;
$misoSHissueBI: #8BC34A;
$misoSHissueV: #B5C0B7;
$misoSHissueFerc: #ff9e18;

    
$misoGray2: #a7a8a9;
$misoLightGray2: #d9d8d6;
$misoOpacityGray:#F7F7F7;
$misoDarkGray2: #636569;
$misoCalendarGray: #8A8A8A;
$misoDividerGrey: #c2c2c3;


$misoPrimaryBlack:#161616;
$white:#fff;
$black:#000;

/* --------//END MISO BASE COLORS ----------*/


/*-------- TYPOPGRAPHY --------------------*/

$misoDefaultFont: 'Lato', Arial, Helvetica, sans-serif;
$misoBold: 'Lato', Arial, Helvetica, sans-serif;
$latoBlack: 'Lato', Arial, Helvetica, sans-serif;
$misoHeaderFont: 'Source Sans', Arial, Helvetica, sans-serif;



/*--------//END TYPOGRAPHY ----------------*/

/*-----------RESPONSIVE VARIABLES-----------*/

$responsiveTablet: 1024px;

$screen-sm-min:  768px;
$screen-md-min:  992px;
$screen-lg-min:  1200px;


/*-------//END RESPONSIVE VARIABLES---------*/



