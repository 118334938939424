$maxWidth: 1570px;

.container-fluid {
    max-width: $maxWidth;
}

@media (min-width: 992px) {
    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1200px) {
    .container-fluid {
        padding-left: 50px;
        padding-right: 50px;
    }
}

body {
    font-family: $misoDefaultFont;
    width: 100%;
    height: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.body-container {
    flex:1;
}
h1 {
    font-size: 48px;
    line-height: 54px;
}

h2 {
    font-size: 36px;
    line-height: 48px;
}

.row > .col-12 > h2 {
    margin-top:0px;
}
h3,.sectionHeader {
    font-size: 28px;
    line-height: 36px;
}
h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;

    &.with-background {
        font-size: 18px;
        line-height: 24px;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        border: 1px solid #d9d8d6;
        border-radius: 4px;
        font-weight: bold;
    }
}
h5 {
    font-size: 12px;
    line-height: 18px;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
    color: $black;
}
.blue-bg {
    background-color: $misoBackgroundBlue;
}

.green-bg {
    background-color: $misoLightGreen;
}




h3 {
	&.light-gray-bg {color:$misoGray;text-shadow:  0 1px 1px rgba(255, 255, 255, 0.5);-webkit-background-clip: text; -moz-background-clip: text;background-clip: text;}
}
.bold {font-weight: bold;}

.italic {font-style: italic;}

.strikeThrough {text-decoration: line-through;}

.padding0 {padding: 0px;}

.misoBold, .latoBold {font-family: $misoBold; font-weight: bold;}

.latoBlack {font-family: $latoBlack; font-weight: bold;}

.uppercase {text-transform: uppercase;}
.inline {display:inline;}
.flex {display: flex;}
/*.d-inline-grid {
    display: inline-grid;

    &.show {
        display:block;
    }
}
.panel .collapsed + d-inline-grid {
    display: block;
}*/
.display-block {
    display: block;
}
.d-inline-grid {
    display:inline-grid;
}
.h-100 {height: 100%;}
.w-auto {width:auto;}
.w-100 {width: 100%;}
.visibility-hidden {
    position: absolute;
    visibility: hidden;
}

a {color: $misoLinkBlue;}
a:not(.btn-primary,.btn-default), .btn-link {
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: underline;
        text-underline-offset: 2px;
        color: $darkBlueHover;
        cursor: pointer;
    }
}
.cta-border { display:none;width:100%;float:left; height:3px; margin-bottom:3px; 
    &.light-gray-bg {display:block;background-color: $misoBorderGray;}
}
.form-control, .alert, .navbar {border-radius:4px;}
label{font-weight:normal;}

a.links {color:$misoLightBlue;
		&:hover{color:$misoGreen;text-decoration:none;}
			}
a.list-group-item {
    color: $misoLinkBlue;
}
.text-decoration-none:hover {
    text-decoration: none;
    .text-underline-hover {
        text-decoration: underline;
    }
}
p {word-wrap:break-word;font-size: 1.0em;margin-bottom:20px}

.btn {
    max-width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

/*------ ERRORS AND WARNINGS ---*/
.has-error {color:$misoRed;font-weight: bold;}


/*----------------- COLORS ----------------*/ 

.green {color:$misoGreen;}
.blue {color:$misoBlue;}
a.blue:hover, a.blue:focus {
    color: $misoDarkBlue;
    text-decoration: none;
}

.teal{color:$misoTeal;}
.black {color: $misoPrimaryBlack;}
.dark-blue {color: $misoDarkBlue;}
.light-blue {color:$misoLightBlue;} 
.gray {color:$misoGray;}
.red {color:$misoRed;}
.orange {color:$misoOrange;}
.dark-grey {color: $misoDarkGray2;}
.white {color:white;font-family: 'Lato', Arial, Helvetica, sans-serif;}


/*-----------------//END COLORS ----------------*/


/*----------- UTILITY CLASSES --------------*/
.z-n1 {z-index:-1;}
.z-0 {z-index:0;}
.z-1 {z-index:1;}

.fs12 {font-size:12px;}
.fs14 {font-size: 14px;}
.fs16 {font-size: 16px;}
.fs18 {font-size: 18px;}

.margin-top-0 {margin-top:0;}
.margin-top-3 {margin-top:3px;}
.margin-top-5 {margin-top:5px;}
.margin-top-10 {margin-top:10px;}
.margin-top-15 {margin-top:15px;}
.margin-top-20 {margin-top:20px;}
.margin-top-25 {margin-top:25px;}
.margin-top-30 {margin-top:30px;}
.margin-top-40 {margin-top:40px;}
.margin-top-50 {margin-top:50px;}
.margin-top-150 {margin-top:150px;}
.margin-top-150 {margin-top:150px;}
.margin-left-130 {margin-left:130px;}
.margin-left-160 {margin-left:160px;}
.margin-left-170 {margin-left:170px;}
.margin-left-5 {margin-left:5px}
.margin-left-10 {margin-left:10px}
.margin-left-15 {margin-left:15px}
.margin-left-20 {margin-left:20px}
.margin-left-25 {margin-left: 25px}
.margin-left-30 {margin-left:30px}
.margin-left-50 {margin-left:50px}
.margin-right-3 {margin-right: 3px;}
.margin-right-5 {margin-right:5px;}
.margin-right-10 {margin-right:10px;}
.margin-right-15 {margin-right: 15px;}
.margin-right-20 {margin-right:20px;}
.margin-btm-0 {margin-bottom:0px;}
.margin-btm-3 {margin-bottom:3px;}
.margin-btm-5 {margin-bottom:5px;}
.margin-btm-8 {margin-bottom: 8px;}
.margin-btm-10 {margin-bottom:10px;}
.margin-btm-15 {margin-bottom:15px}
.margin-btm-20 {margin-bottom:20px;}
.margin-btm-30 {margin-bottom:30px;}
.margin-btm-150 {margin-bottom:150px;}
.padding-0 {padding:0px;}
.padding-15 {padding: 15px;}
.padding-top-0 {padding-top:0;}
.padding-top-3 {padding-top:3px;}
.padding-top-5 {padding-top:5px;}
.padding-top-10 {padding-top:10px;}
.padding-top-15 {padding-top:15px;}
.padding-top-20 {padding-top:20px;}
.padding-top-25 {padding-top:25px;}
.padding-top-30 {padding-top:30px;}
.padding-top-40 {padding-top:40px;}
.padding-top-50 {padding-top:50px;}
.padding-top-150 {padding-top:150px;}
.padding-top-150 {padding-top:150px;}
.padding-left-130 {padding-left:130px;}
.padding-left-160 {padding-left:160px;}
.padding-left-170 {padding-left:170px;}
.padding-left-0 {padding-left: 0px;}
.padding-left-3 {padding-left: 3px}
.padding-left-5 {padding-left:5px}
.padding-left-10 {padding-left:10px}
.padding-left-15 {padding-left:15px}
.padding-left-20 {padding-left:20px}
.padding-left-25 {padding-left: 25px}
.padding-left-30 {padding-left:30px}
.padding-left-50 {padding-left:50px}
.padding-right-0 {padding-right: 0px;}
.padding-right-3 {padding-right: 3px;}
.padding-right-5 {padding-right:5px;}
.padding-right-10 {padding-right:10px;}
.padding-right-15 {padding-right: 15px;}
.padding-right-20 {padding-right:20px;}
.padding-right-25 {padding-right:25px;}
.padding-btm-0 {padding-bottom:0px;}
.padding-btm-3 {padding-bottom:3px;}
.padding-btm-5 {padding-bottom:5px;}
.padding-btm-10 {padding-bottom:10px;}
.padding-btm-15 {padding-bottom:15px}
.padding-btm-20 {padding-bottom:20px;}
.padding-btm-30 {padding-bottom:30px;}
.padding-btm-40 {padding-bottom: 40px;}

//flexbox alignment classes
.alignCenter {
    display: flex;
    justify-content: center;
}





.gray-border-15 {
    border: 15px solid #ccc;
}
.gray-border-1 {
    border-bottom: 1px solid $misoBorderGray;
}
 
 .text-xs-left { text-align: left; }
 .text-xs-right { text-align: right; }
 .text-xs-center { text-align: center; }
 .text-xs-justify { text-align: justify; }

 @media (min-width: $screen-sm-min) {
     .text-sm-left { text-align: left; }
     .text-sm-right { text-align: right; }
     .text-sm-center { text-align: center; }
     .text-sm-justify { text-align: justify; }
 }

 @media (min-width: $screen-md-min) {
     .text-md-left { text-align: left; }
     .text-md-right { text-align: right; }
     .text-md-center { text-align: center; }
     .text-md-justify { text-align: justify; }
 }

@media (min-width: $screen-lg-min) {
    .text-lg-left { text-align: left; }
    .text-lg-right { text-align: right; }
    .text-lg-center { text-align: center; }
    .text-lg-justify { text-align: justify; }
}

.row.g-0 > .block {margin-bottom:0px;}
.row.g-1 > .block {margin-bottom: 0.25px;}
.row.g-2 > .block {margin-bottom: 0.5px;}

/*.row.no-gutters [class^="col-"],
.row.no-gutters [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}*/

/*-----------//END  UTILITY CLASSES --------------*/

/*-------------------FPO WRAPPER------------------*/

.fpo-wrapper{border:1px solid $misoGray;height:400px;font-size:2em;color:$misoLightGray;}

/*-------------------//END FPO WRAPPER------------------*/



/*.popoverLogin, .popoverRequire, .popoverRequireReset, .popoverStatus {display: none;width: 330px;max-width: 330px;padding: 25px 15px;text-align: left;-webkit-box-shadow:inset 0px 0px 0px 10px #ccc;-moz-box-shadow:inset 0px 0px 0px 10px #ccc;box-shadow:inset 0px 0px 0px 10px #ccc;border-radius: 0px !important;background-color: white;color:#333;top:20px !important;
	a.links {color:$misoLightBlue;font-size: 1.125em;
		&:hover{text-decoration:none;}
	}
	a.btn-primary {border-bottom: 3px solid #3db5e6 !important;background-color: $misoBlue !important;width:100%;
		&:hover{border-bottom:3px solid $misoBlue !important;background-color: #286090 !important;color:$white;}
	}
}

.popoverRequire, .popoverRequireReset {width:425px;max-width:425px;top:20px !important;
	ul{padding-left:12px;}
}

.popoverStatus{width:650px;max-width:650px;margin-left:-290px;}

.popoverRequireReset {top:85px !important;left:60px !important;}

a.pwRequire {
	&:link,&:hover,&:focus{
	text-decoration: none;cursor:pointer;
	}
}

.popover.bottom > .arrow, .popover.bottom > .arrow:after {
	display:none;
}
*/
/*--------fontsizes----------*/
.fs-16{font-size:16px;}
.fs-24 {font-size:24px;}


/*--------fontsizes----------*/