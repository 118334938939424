/*----------- BUTTONS ----------------*/
.roundedBands, .highcharts-dial {
    stroke-linejoin: round;
    stroke-linecap: round;
}
path.insetShadowSVG {
    filter: url("#inset-shadow");
}
.highcharts-credits {
    display:none;
}
.btn {
    &.greyOut {
        opacity: .4;
        cursor: initial;
    }
}

.btn-primary {
    border-radius: 4px;
    border: 2px solid transparent;
    padding: 10px 20px;
    background-color: $misoDarkBlue;
    color: $white;

    &:hover {
        background-color: $darkBlueHover;
        transition: background-color 0.3s ease-in;
    }

    &:focus {
        background-color: $darkBlueHover;
    }

    &:disabled {
        opacity: .4;
        border: 2px solid transparent;
        background-color: $misoDarkBlue;
    }
}

.btn-primary-light-blue {
    border-radius: 4px;
    border: 2px solid transparent;
    padding: 10px 20px;
    background-color: #E6F3FA;
    color: #004d71;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    text-align: left;

    i {
        color: $misoGreen;
    }

    &:hover, &:focus,&:active {
        transition-property: color;
        background-color: $scta_LightBlue;
        color: $darkBlueHover;
    }
}

.btn-default {
    color: $misoDarkBlue;
    border-radius: 4px;
    border: 2px solid $misoDarkBlue;
    padding: 10px 20px;
    background-color: transparent;
    &:hover {
        background-color: $misoGray;
        color: $misoDarkBlue;
        opacity: 0.6;
        border-color: $misoDarkBlue;
        transition: background-color 0.3s ease-out;
    }
}
.btn-default-lw {
    color: $misoDarkBlue;
    border-radius: 4px;
    border: 1px solid $misoDarkBlue;
    padding: 10px 14px;
    background-color: transparent;

    &:hover {
        background-color: $misoGray;
        color: $misoDarkBlue;
        opacity: 0.6;
        border-color: $misoDarkBlue;
        transition: background-color 0.3s ease-out;
    }
}
.btn-success {
    background-color: $misoGreen;
    border-radius: 4px;
    border: 2px solid transparent;
    font-weight: bold;
    border-top: 1px solid #81bc00;
    padding: 10px 20px;

    &:hover {
        background-color: $misoDarkGreen;
        transition: background-color 0.3s ease-in;
    }

    &:focus {
        outline: none;
        background-color: $misoGreen
    }
}
.btn-miso-blue {
    background-color: $misoBlue;
    border-radius: 4px;
    border: 2px solid transparent;
    font-weight: bold;
    padding: 10px 20px;
    color: $white;

    &:hover {
        background-color: $misoDarkBlue;
        transition: background-color 0.3s ease-in;
        color: $white !important;
        text-decoration: none !important;
    }

    &:focus {
        outline: none;
        background-color: $misoBlue
    }
}
.btn-miso-red {
    background-color: $misoRed3;
    border-radius: 4px;
    border: 2px solid transparent;
    font-weight: bold;
    padding: 10px 20px;
    color: $white;

    &:hover {
        background-color: $misoDarkRed2;
        transition: background-color 0.3s ease-out;
        color: $white !important;
        text-decoration: none !important;
    }

    &:focus {
        outline: none;
        background-color: $misoRed3
    }
}
.btn-miso-orange {
    background-color: $misoOrange2;
    border-radius: 4px;
    border: 2px solid transparent;
    font-weight: bold;
    padding: 10px 20px;
    color: $white;

    &:hover {
        background-color: $misoDarkOrange2;
        transition: background-color 0.3s ease-out;
        color: $white !important;
        text-decoration: none !important;
    }

    &:focus {
        outline: none;
        background-color: $misoOrange2
    }
}
.btn-negative {
    background-color: $misoRed;
    border-radius: 4px;
    border: 2px solid transparent;
    padding: 10px 20px;
    color: white;

    &:hover {
        background-color: $misoDarkRed2;
        color: white;
        transition: background-color 0.3s ease-in;
    }

    &:focus {
        background-color: $misoDarkRed2;
        color: white;
    }

    &:active {
        color: rgba(255, 255, 255, 0.75);
    }
}

.btn-small {font-size: 12px; line-height: 1em;}

/*---------//END BUTTONS -------------*/


/*----------- MODALS ----------------*/
#loginElement,
.resetPassword,
.forgotPassword {
	.login-footer{border-top:0; padding-top:0; padding-left:0; padding-right:0; padding-bottom: 15px; }
	button.btn {width:100%}
    .alert-info {float:left; width:100%;}
    .loginContainer {
        border: 15px solid #ccc;
        padding: 10px 20px 20px 20px;
        background-color: #ffffff;
        width:auto; 
        min-width:300px; 
        max-width:550px;
        margin-left:auto;
        margin-right:auto;
        a.links {text-align:center;}
        .light-blue-link{a{color:$misoLightBlue;}}
    }

}
#loginElement {
    .loginContainer {
        hr {margin:0px; border:0;}
    }
}
.login-workflow {
    .boldLink {color: #31708f;}
}
.login-form {
	margin: 0;
}

.has-feedback label ~ .form-control-feedback {
	top: 30px;
	right: 15px;
}

.login-workflow { padding: 15px 0; float: left; text-align: left;
    &.alert-info, .alert-info { float: left;
        p { text-align: left; } 
    }    
}
.resetPassword {

}

/*---------//END MODALS -------------*/

/*---------MESSAGE STYLES -------------*/
.message {
    div.message-text { display: table; min-height: 65px; width: 100%; 
        span { display: table-cell; vertical-align: middle; text-align: center; }
    }
}
/*---------END MESSAGE STYLES -------------*/

/*---------CTA STYLES -------------*/
.calltoactionblock {
    .cta-block {

        &.backgroundCTA {
            border: 1px solid $misoBorderMedGray;
            background: $misoNearWhite url(../img/MISO-Logo-White.png) no-repeat top -50px right -25px;
        }
        &.withBorder {
            .btn {
                border-radius:0px 0px 4px 4px;
            }
        }
    }
}
/*---------//END CTA STYLES -------------*/


/*---------ACCORDION STYLES -------------*/
.accordion-content {
    .panel-group {
        margin-bottom: 0px;
    }

    .panel {
        background-color: transparent;
        border: 1px solid $misoDividerGrey;
        border-radius: 4px;
        //margin-top: 0px !important;
        box-shadow: none;
        margin-bottom: 10px;

        .collapse.in {
            box-shadow: none;
        }

        .panel-heading {
            background-color: transparent;
            border: none;
            padding: 15px;
            display: flex;

            .leftside {
                flex-grow: 2;
            }

            .rightside {
                flex-grow: 0;
                align-self: center;

                a {
                    i.fa {
                        color: $misoGreen;
                    }

                    i.fa::before {
                        color: $misoBlue;
                        content: "\f068";
                    }

                    &.collapsed i.fa::before {
                        color: $misoGreen;
                        content: "\f067";
                    }
                }
            }

            h3.panel-title {
                width: 100%;

                a {
                    font-weight: bold;
                    color: $misoBlue;
                    font-size: 26px;

                    i.fa {
                        color: $misoGreen;
                    }

                    i.fa::before {
                        content: "\f068";
                        color: $misoBlue;
                    }

                    &.collapsed i.fa::before {
                        content: "\f067";
                    }
                }
            }

            h3.subTitle {
                font-size: 16px;
                color: #7f8f90;
            }

            h4.panel-title {
                width: 100%;

                a {
                    color: $misoBlue;
                    display: flex;
                    text-decoration: none;

                    &.mailingList-title {
                        color: $misoPrimaryBlack;
                    }

                    i.fa {
                        color: $misoGreen;
                        align-self: center;
                    }

                    i.fa::before {
                        color: $misoBlue;
                        content: "\f068";
                    }

                    &.collapsed i.fa::before {
                        color: $misoGreen;
                        content: "\f067";
                    }

                    .panel-title-text {
                        flex-grow: 2;
                    }
                }

                .subscription-date {
                    font-weight: normal;
                }
            }

            h3 {
                color: $misoDarkGray2;
                font-size: 14px;
                margin-top: 10px;
                margin-bottom: 0px;
                line-height: 14px;
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }


        .panel-body {
            border: none !important;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 16px;
            padding-bottom:5px;
            .thumbnail {
                border: none;
                border-radius: 0px;
                margin-bottom: 0px;
            }
        }
        
    }

    .mailing-list-panel {
        background-color: $misoOpacityGray;
        border: 1px solid $misoLightGray2;
        padding: 5px 15px;
        color: $misoDarkGray2;

        button {
            color: $misoDarkBlue;

            &:hover {
                color: $darkBlueHover;
                transition: color 0.3s ease-in;
            }

            .fa {
                padding-left: 10px;
                font-size: 14px;
            }
        }
    }
}
/*---------//ACCORDION STYLES -------------*/
/*---------//Editorial  STYLES -------------*/
.editorialblock img{
    max-width:100%;
    border-radius:4px;
}
/*---------//END Editorial STYLES -------------*/
/*---------//CONTENT ALERT STYLES -------------*/
.dropdown-toggle > .badge {
    padding: 2px 4px;
    font-size: 9px;
    background-color: $misoRed;
    border-radius: 50%;
    right: -5px;
    top: 0px;
    position: absolute;
}

button.content-alert {
    background-color: $white;
    border: 1px solid $misoBorderGray;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    margin: 5px 0;
    width: 100%;
    text-align: left;

    div.content-alert-link {
        color: $misoDarkBlue;
    }

    &:hover {
        background-color: $misoNearWhite;
        cursor: pointer;

        div.content-alert-link {
            text-decoration: underline;
            color: $darkBlueHover;
        }
    }

    a:hover {
        text-decoration: none;
    }

    table {
        font-size: 20px;

        tr {
            height: 40px;
        }
    }

    > .content-alert-info {
        font-size: 12px;
        color: #333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
    }
}

.content-alertgroup {
    margin-bottom: 10px;
}

.alerticon-dropdown {
    width: 400px;
    font-family: $misoDefaultFont;
    font-size: 14px;
    color: #333;
    text-shadow: none;
    font-style: initial;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 25%);

    button.content-alert > .content-alert-info {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
    }

    p {
        margin-top: 10px;
        margin-bottom: 0px;
        padding-top: 10px;
        border-top: 1px solid $misoBorderGray;
    }

    .content-alert-link {
        margin: 0px;
        font-size: 14px;

        &:hover {
            text-decoration: underline;
            color: $darkBlueHover;
        }
    }
}

/*---------//CONTENT ALERT STYLES -------------*/

/*---------//SECTION STYLES -------------*/
.section-callout {border: 1px solid $misoBorderMedGray;padding:20px;background:$misoNearWhite;
    .section-title {color:$misoGray;font-size:24px;margin-top:0px;margin-bottom:10px;}
}
/*---------//END SECTION STYLES -------------*/

/*---------//MY ALERTS STYLES -------------*/
.miso-checkbox {
    border-radius: 2px !important;
    margin-left: 0 !important;

    &:checked {
        background-color: $misoDarkBlue;
        border-color: $misoDarkBlue;
    }
}
/*---------//END MY ALERTS STYLES -------------*/

/*---------//DATATABLE STYLES -------------*/
.popoverStatus { position:absolute; max-width: 675px; left: auto !important; right: 15px; width: calc(100% - 30px);}

#toggleButtons { display: none; }

.dataTables_paginate span { 
    display: table-cell !important; 
    .ellipsis {
        padding: 0px 10px;
    }
}

.miso-dt-table {
    .filters.row {
        padding-bottom: 20px;

        h2 {
            color: $misoBlue;
        }

        label {
            font-weight: bold;
            color: $misoPrimaryBlack;
        }

        .activeStatus {
            order: 1;
        }

        .issueCategory{order:2;}
        .primaryCommitteeAcronym {
            order: 3;
        }
    }
}
.processingIndicator {
    text-align:center;
    color:#CCC;
    opacity:.7;
    font-size:36px;
    &.row { width: 100%; float: left; }
}
.legendRow {
    display:none;
}

.GIQueueTable {

}
.miso-dt-filters .form-control {
    border-radius: 4px;

    &:after {
        margin-right: 10px;
    }
}
.sortableth {
    position:relative;
    &:before, &:after {
        font: normal normal normal 10px/1 FontAwesome;
        right: 5px;
        float: right;
        color: $misoGray2;
        position: absolute;
        display: block;
        opacity: .6;
    }

    &:before {
        content: "\f062";
        bottom: 50%;
    }

    &:after {
        content: "\f063";
        top: 50%;
    }
    &--asc:before, &--desc:after {
        color: $misoGreen;
    }
}

.miso-dt {
    display: none;
    padding-top: 10px;

    &.table-responsive {
        border: none;
        border-top: 2px solid $misoDividerGrey;
    }

    .tableHeader {
        display: flex;
        justify-content: space-between;
        align-items: end;

        #tableSearch .form-control {
            height: 41px;
            border: none;
            box-shadow: none;
            font-size: 16px;
            border-radius: 4px;
        }

        .form-group {
            background-color: $white;
            height: 44px;
            border: 1px solid $misoLightGray2;
            border-radius: 4px;
            display: flex;
            align-items: baseline;

            .fa-search {
                color: $misoGreen;
                margin: 4px 10px 4px 4px;
                text-align: center;
            }
        }

        #exportButton {
            display: flex;

            .legendComp {
                order: 2;
            }

            .dt-button.buttons-csv.btn-link, .legend-popover {
                color: $misoDarkBlue;
                border: none;
                border-radius: 0px;
                border-left: 1px solid $misoLightGray2;
                background: white;
                margin: 0px;
                padding: 0px 10px 0px 10px;
                font-weight: 700;
                font-size: 16px;

                .fa {
                    margin-right: 7px;
                }

                &:hover, &:focus {
                    color: $misoGreen;
                }
            }
        }
    }


    .dataTables_filter {
    }

    .dataTables_processing {
    }

    &.IssueOverview {
        .dataTables_scrollHead {
            font-size: 12px;
            font-weight: 900;
            line-height: 18px;
        }

        .dataTables_scroll {
            margin-top: 10px;
            font-size: 16px;

            .odd {
                background-color: $white;
            }

            .btn-small {
                font-size: 16px;
            }
        }
    }

    &.GIQueue {
        .dataTables_scroll {
            margin-top: 10px;
            font-size: 12px;

            .odd {
                background-color: $white;
            }
        }
    }

    .dataTables_scroll {
        margin-top: 10px;
        font-size: 12px;

        .odd {
            background-color: $white;
        }
    }

    .dataTables_scrollHeadInner {
        padding-right: 0px;
        max-width: 100%;

        table {
            margin-bottom: 0px;
        }
    }

    .dataTables_scrollHead table thead {
        @extend .miso-stripeless-table-header;

        tr {
            cursor: pointer;
        }

        tr:first-child th {
            padding: 5px;
            border-top: 1px solid $misoLightGray2;
            vertical-align: middle;
        }

        tr:first-child th:first-child:not([style*="display:none"]) {
            border-top-left-radius: 4px;
        }

        tr:first-child th:last-child {
            border-top-right-radius: 4px;
        }

        th.sorting {
            @extend .sortableth;
        }

        th.sorting_asc:before, th.sorting_desc:after {
            color: $misoGreen;
        }

        .year-row {
            display: none;
        }
    }

    .dataTables_scrollBody {
        border-bottom: 1px solid $misoLightGray2;

        .miso-table {
            border-top: 0px;
            margin-bottom: 0px;
        }

        td {
            padding: 5px;
            border-top: none;
        }


        .phase {
            vertical-align: middle;
        }

        .requestStatus {
            text-align: center;
            padding: 4px 11px;
            border-radius: 4px;

            &.Active {
                color: $misoDarkGreen;
                background-color: $misoVeryLightGreen;
            }

            &.Withdrawn {
                color: $misoRed3;
                background-color: $misoLightRed2;
            }

            &.Done {
                color: $misoBlue;
                background-color: $scta_LightBlue;
            }

            &.Inactive {
                color: $misoOrange;
                background-color: $scta_LightOrange;
            }
        }
    }

    .dataTables_scrollHead thead th, .dataTables_scrollBody tbody td {
        border-left: none;
        border-top: 1px solid #d9d8d6;
        border-right: 1px solid $misoLightGray2;
        border-bottom: none;
        word-wrap: break-word;
        padding: 5px;
    }

    .dataTables_scrollHead thead th:first-child, .dataTables_scrollBody tbody td:first-child {
        border-left: 1px solid $misoLightGray2;
    }

    @media screen and (max-width: 991px) {
        .card-hidden {
            display: none;
        }
    }

    table.dataTable th, table.dataTable td {
        box-sizing: border-box;
    }

    table.dataTable th.dt-no-wrap, table.dataTable td.dt-no-wrap {
        white-space: nowrap;
    }

    l1 {
        width: 100%;
    }

    @media screen and (min-width: 991px) {
        td.text-center-desktop {
            text-align: center;
        }
    }
}
/*---------//Pagination STYLES --------------*/


.dataTablesFooter {
    border: 1px solid $misoLightGray2;
    border-top: none;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.pagination-controls {
    padding-top: 10px;
    border-top: 1px solid $misoLightGray2;
}

.dataTablesFooter, .pagination-controls {
    display: flex;
    padding-bottom: 5px;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 4px 4px;
    width: 100%;

    .dataTables_info {
        padding-top: 0px;
        padding-right: 15px;
    }

    @media screen and (max-width: 768px) {
        .dataTables_info {
            display: none;
        }
    }

    .dataTables_length, .pager-text {
        label {
            margin-bottom: 0px;
        }

        select {
            height: 35px;
            border: 1px solid $misoLightGray2;
            border-radius: 4px;
            margin: 0px 5px;
        }
    }

    .rightSide {
        display: flex;
        align-items: center;
    }

    .dataTables_paginate {
        width: auto;
        text-align: end;
        display: flex;
        border-top: none;
        padding-top: 0px;
        margin-bottom: 0px;

        span {
            display: flex;
        }

        span .ellipsis {
            display: inline-block !important;
            height: 35px;
            width: 35px;
            margin: 0px;
            padding: 4px 5px;
            border-left: 1px solid $misoLightGray2;
            border-radius: 0px;
            justify-content: center;
            vertical-align: middle;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            color: $misoDarkBlue !important;
        }

        a.paginate_button {
            display: inline-block;
        }

        .paginate_button {
            height: 35px;
            width: 35px;
            margin: 0px;
            padding: 4px 5px;
            border: 1px solid $misoLightGray2;
            border-right: 0px;
            border-radius: 0px;
            justify-content: center;
            vertical-align: middle;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            color: $misoDarkBlue !important;

            &.current, &:hover, &.current:hover, &:active {
                background: $misoVeryLightGreen;
                //color: $misoDarkBlue !important;
            }

            &.disabled {
                border-color: $misoLightGray2;

                &:hover, &:focus {
                    cursor: not-allowed;
                    border-color: $misoLightGray2;
                }
            }
        }

        .ellipsis {
            text-align: center;
        }

        .first {
            border-radius: 4px 0px 0px 4px;
        }

        .previous {
            border-radius: 4px 0px 0px 4px;
        }

        .first + .previous {
            border-radius: 0px;
        }

        .last {
            border-right: 1px solid $misoLightGray2;
            border-radius: 0 4px 4px 0;
        }

        .next:has(+ .last) {
            border-right: none;
            border-radius: 0;
        }

        .next {
            border-right: 1px solid $misoLightGray2;
            border-radius: 0 4px 4px 0;
        }
    }
}



/*---------// END Pagination STYLES --------------*/
.miso-dt-table option.fa:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\F10C ";
    padding-right:3px;
}
.miso-dt-table option {
    display: block;
    margin: 2px 0px;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.miso-dt-table option:after {
}
.miso-dt-table option.fa:checked:enabled:before {
    content: "\F058 ";
}
.dataTables_paginate{
    ul.pagination {
        li {
            font-size:16px;
        }
        li.disabled {
            a {
                cursor:default;
            }
        }
    }
}
.miso-dt {
    table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
        padding-right: 15px;
        position: relative;
    }
}
.dataTables_scrollHead table.dataTable { margin-top: 0px !important; }

table.dataTable { 
	>tbody>tr.child ul.dtr-details {
		list-style: none;
		padding-left: 20px;
        width:100%;
		li {
			border-bottom: 0px !important;
			.dtr-title { font-weight:bold; width: 33%; display: inline-block; vertical-align: top; }
            .dtr-data { width: 66%; display: inline-block; .mrIcon{font-size:16px; margin-bottom:0px; line-height:16px;} }
            .phase-icon {
                margin:0px;
                height:auto;
            }
		}

	}
}
.phase-icon {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    text-align: center;
    color: $white;

    &.i {background-color: $misoDarkPurple;}
    &.b {background-color: $misoSHissueBI;}
    &.cd {background-color: $misoSHissueECD;}
    &.e {background-color: $misoSHissueECD;}
    &.v {background-color: $misoSHissueV;}
    &.f {background-color: $misoSHissueF;}
    &.d {background-color: $misoSHissueV;}

    &.ferc::before {background-color: $misoSHissueFerc;}

    &.ferc {background-color: $misoSHissueFerc;}

    &.bpm {
        background-color: $misoDarkGray2;
        font-size: 11px;
        padding: 5px 0px;
    }

    &.ferc-border {border: $misoSHissueFerc 3px solid;}
    &.bpm-border {border: $misoDarkGray2 3px solid;}
    &.no-border {border: transparent 3px solid;}
    &.bpm.ferc-border {
        border: $misoSHissueFerc 3px solid;
        padding: 5px 0px;
        font-size: 11px;
    }
    &.ferc-with-bpm-border {
        padding: 0px 0px;
        border: $misoSHissueFerc 3px solid;
        box-shadow: 0px 0px 0px 3px $misoDarkGray2;
        height: 27px;
        width: 27px;
    }
}
@media screen and (max-width: 991px)  {
    #toggleButtons { display: none; }
	.dataTables_wrapper .dt-buttons { display: none; }
}

@media screen and (min-width: 992px)  {
    #toggleButtons { display: table-cell; }
}



/*---------//END DATATABLE STYLES -------------*/

    /*---------//ACCOUNT STYLES -------------*/
    .login-footer {
        padding-bottom: 20px;
    }

    .popoverRequireReset {
        max-width: calc(100% - 70px);
    }
    /*---------//END ACCOUNT STYLES -------------*/
    /*---------//MAILING LIST STYLES -------------*/
    .mailing-list-subscriptions {
        .tab-content-block {
            ul.nav-tabs {
                text-align: left;

                li {
                    display: inline-block;
                    float: none;
                }
            }

            .tab-content {
                float: left;

                .mailingList-panel {
                    .panel-heading {
                        padding: 20px 0 !important;

                        h4.panel-title {
                            vertical-align: text-top;

                            .list-checkbox {
                                display: inline-block;
                                width: 20px;
                            }

                            .list-title {
                                display: inline-block;
                                width: calc(100% - 70px);
                                margin-left: 20px;

                                &.closed {
                                    width: calc(100% - 30px);
                                    margin-left: 0px;
                                }
                            }

                            .list-toggle {
                                display: inline-block;
                                width: 30px;
                            }
                        }

                        .subscription-date {
                            margin-top: 10px;
                            padding-left: 40px;
                            font-size: 16px;
                            font-weight: normal;
                        }
                    }

                    .panel-body {
                        padding-left: 40px !important;

                        &.closed {
                            padding-left: 0px !important;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 991px) {
            .tab-content-block {
                ul.nav-tabs {
                    text-align: left;

                    li {
                        display: block;
                    }
                }

                .tab-content {
                    float: left;
                    margin-top: 5px;
                    border: none;
                    padding: 0;
                }
            }
        }
    }
    /*---------//END MAILING LIST STYLES -------------*/
    /*---------//FORM PANEL LIST STYLES -------------*/
    .notifications-filters.filters.row {
        border-bottom: 2px solid #bbc1c2;
        padding-bottom: 20px;

        h2 {
            padding-left: 15px;
            color: $misoBlue;
        }

        .btn-primary {
            float: right;
            margin-top: 25px;
        }

        @media screen and (max-width: 991px) {
            .btn-primary {
                float: left;
                margin-top: 5px;
            }
        }
    }

    .notifications-categories {
        h2 {
            color: $misoBlue;
        }

        .panel-heading {
            h5 {
                display: inline-block;
                margin: 0px;
                margin-left: 15px;
                font-weight: normal;
            }
        }

        input {
            width: 100%;
        }

        .shareLink {
            cursor: pointer;
            height: 34px;
            padding: 6px 10px;
        }

        .showItem {
            display: inline-block;
        }
    }
    /*---------//END FORM PANEL LIST STYLES -------------*/
    /*---------//NOTIFICATION PAGE STYLES --------------*/

    .notificationSubjectRow {
        .italicized {
            font-style: italic;
        }

        .notificationName {
            font-size: 24px;
            font-weight: bold;
        }

        .notificationDate {
            font-size: 14px;
            white-space: nowrap;
        }
    }


/*---------//END NOTIFICATION PAGE STYLES --------------*/



/*---------//RESPONSIVE IFRAME STYLES --------------*/
.embed-responsive { min-height: 150px; padding-bottom: 20px; }
.touch .embed-responsive-16by9, .touch .embed-responsive-4by3 {
    -webkit-overflow-scrolling: touch;
    overflow: scroll !important;
}
.iframeblock {
    margin-bottom: 0px;

    .embed-responsive {
        display:contents;
    }
}
/*---------//END RESPONSIVE IFRAME STYLES --------------*/

/*---------//INDIVIDUAL ISSUES PAGE STYLES --------------*/
#IssuePage {
    .titleRow {
        h2 {

            display: inline-block;
        }
        h4 {
            font-size:12px;
            font-weight:normal;
        }
/*        h5 {
            .lastModified {
                font-weight: bold;
                font-style: italic;
            }
        }*/
        }

        table {
            width: 100%;

            th {
                border-bottom-width: 2px;
            }

            td, th {
                padding: 8px;
                text-align: center;
            }

            .phase-icon {
                margin: auto;
            }
        }

        table, th, td {
            border: 1px solid #ddd;
        }

        @media screen and (max-width: 768px) {
            .tableRow {
                display: none;
            }
        }


        p {
            font-size: 16px;
        }

        .labelBorderBottom {
            border-bottom: 1px solid #cdd7d8;
            float: left;
            width: 100%;
        }

        .labelBorderTop {
            border-top: 1px solid #cdd7d8;
            float: left;
            width: 100%;
        }

        .noPadding {
            padding-left: 0px;
            padding-right: 0px;
        }

        .summary, .purpose {
            h4 {
                margin-top: 10px;
            }
        }

        .summary {
            img {
                width: 100%;
                height: auto;
            }
        }

        .principles {
            padding: 20px 20px 20px 0px;

            h4 {
                margin-top: 10px;
            }
        }

        .focus {
            padding: 20px 0px 20px 20px;

            h4 {
                margin-top: 10px;
            }
        }

        @media screen and (max-width: 991px) {
            .focus {
                padding-left: 0px;
            }
        }

        .purpose {
            h4 {
                margin-top: 0px;
            }
        }

        .statusRow {
            padding-bottom: 20px;
            float: left;
            width: 100%;
            padding-top: 20px;

            h4 {
                margin-bottom: 0px;
                margin-top: 10px;
            }

            .ferc-with-bpm-border {
                margin-top: 5px;
            }
        }

        .panel-group {
            margin-bottom: 0px;
        }

        .accordionblock {
            float: left;
            width: 100%;
        }

        .noItems {
            color: $misoGray;
            opacity: .4;
        }

        .phase-icon {
            word-wrap: normal;
            width: 35px;
            height: 35px;
            padding: 7px 2px;
            border-radius: 50%;
            text-align: center;
            color: $white;
            /*&.pi { background-color: $misoOrange; }*/
            &.I {
                background-color: $misoDarkPurple;
            }

            &.B {
                background-color: $misoSHissueBI;
            }
            /*&.sd { background-color: $misoGray; }*/
            &.CD {
                background-color: $misoSHissueECD;
            }

            &.E {
                background-color: $misoSHissueECD;
            }

            &.V {
                background-color: $misoSHissueV;
            }

            &.D {
                background-color: $misoSHissueV;
            }

            &.F {
                background-color: $misoSHissueF;
            }

            &.BPM {
                background-color: $misoDarkGray2;
                font-size: 14px;
                padding: 9px 0px;
            }

            &.BPM.ferc-border {
                border: $misoSHissueFerc 3px solid;
                padding: 6px 0px;
                font-size: 14px;
            }

            &.FERC::before {
                display: block;
                content: "F";
                opacity: 1;
                background-color: $misoSHissueFerc;
                width: 35px;
                height: 35px;
                padding: 7px 2px;
                border-radius: 50%;
                text-align: center;
            }

            &.FERC {
                padding: 0px;
                display: block;
            }

            &.Ferc::before {
                display: block;
                content: "F";
                opacity: 1;
                background-color: $misoSHissueFerc;
                width: 30px;
                height: 30px;
                padding: 4px 2px;
                border-radius: 50%;
                text-align: center;
            }

            &.Ferc {
                padding: 0px;
                display: block;
            }

            &.ferc-border {
                padding: 3px 0px;
                border: $misoSHissueFerc 4px solid;
            }

            &.bpm-border {
                padding: 3px 0px;
                border: $misoDarkGray2 4px solid;
            }

            &.no-border {
                border: transparent 0px solid;
            }

            &.bpm-with-ferc-border {
                padding: 2px 0px;
                border: $misoDarkGray2 4px solid;
                box-shadow: 0px 0px 0px 4px $misoSHissueFerc;
                margin-top: 5px;
            }

            &.ferc-with-bpm-border {
                padding: 2px 0px;
                border: $misoSHissueFerc 3px solid;
                box-shadow: 0px 0px 0px 3px $misoDarkGray2;
                height: 32px;
                width: 32px;
            }
        }
    }
    /*---------//END INDIVIDUAL ISSUES PAGE STYLES --------------*/
    /*---------//INDIVIDUAL EVENTS PAGE STYLES --------------*/
.button-bar {
    padding-bottom: 10px;
    border-bottom: 2px solid $misoBorderGray;
}

.eventPage2 {

    .eventPageHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .btn-default {
            margin-top: 0px;
        }
    }

    .eventsRow {
        padding-top: 10px;
    }

    .labelBorderBottom {
        border-bottom: 2px solid #cdd7d8;
    }

    .viewCalendar {
        justify-content: center;
    }

    .buttonsRow {
        .btn {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .buttonsArea, .metaDataArea, .statusArea, .detailsArea, .documentsArea {
        display: grid;
    }

    .metaDataArea {
        clear: both;
        margin-top: 10px;
    }

    .detailsArea {
        float: revert;
    }

    .documentsArea {
        float: revert;
    }

    @media screen and (max-width: 991px) {
        .labelBorderBottom {
            border-bottom: none;
        }

        .statusArea {
            order: 1;
            margin-bottom: 20px;
        }

        .metaDataArea {
            order: 2;
            border-bottom: 2px solid #cdd7d8;
            margin-bottom: 20px;
        }

        .buttonsArea {
            order: 3;
            border-bottom: 2px solid #cdd7d8;
            margin-bottom: 20px;
        }

        .detailsArea {
            order: 4;
            border-bottom: 2px solid #cdd7d8;
            margin-bottom: 20px;
        }

        .documentsArea {
            order: 5;
        }

        .eventsRow {
            display: grid;
        }
    }
}
/*---------//END INDIVIDUAL EVENTS PAGE STYLES --------------*/
/*---------//EVENT LIST BLOCK STYLES --------------*/
.eventBlock2 {
    .tab-content-block {
        margin-bottom: 0px;
    }

    .dropdown {
        display: inline-block;
        text-align: left;
        font-size: 16px;

        #dropdownMenuButton {
            width: 165px;
            text-align: left;

            .fa {
                padding: 2px 0px;
            }
        }

        .dropdown-menu {
            min-width: 165px !important;
            background-color: #fff;
            width: 165px;

            .dropdown-item {
                text-align: left;
                display: block;
                padding: 2px 5px;
                width: 100%;
            }
        }
    }

    .viewCalendar {
        width: 100%;
        text-align: left;
        margin-bottom: 0px;
        margin-top: 0px;

        .fa {
            margin-right: 10px;
            font-size: 16px;
        }
    }

    .eventList.panel-body {
        padding: 0px 10px !important;
    }

    .eventList {
        margin-bottom: 0px;
        border: 2px solid #cdd7d8;
        padding: 0px;

        .panel-heading {
            padding: 20px 10px;
        }

        .padding10 {
            padding: 0px 10px;
            margin-bottom: 10px;

            h4 {
                font-size: 16px;
            }

            h4.panel-title {
                font-size: 18px;
            }

            .emptyListText {
                margin: 15px 10px 15px 10px;
                color: #7f8f90;
                font-weight: bold;
                font-size: 16px;
            }

            ul {
                padding-left: 0px;
                list-style: none;

                li {
                    border-bottom: 2px solid #cdd7d8;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    margin-top: 0px;
                    margin-bottom: 0px;

                    &:last-child {
                        border-bottom: none;
                        margin-bottom: 10px;
                    }

                    h2 {
                        margin: 0px 0px 5px 0px;
                        font-size: 22px;
                        color: #333;
                    }

                    h2.eventDateHeader {
                        font-size: 18px;
                    }

                    .eventURL {
                        font-size: 16px;
                        color: $misoLightBlue;
                        margin-left: 10px;

                        &:hover {
                            color: $misoGreen;
                            cursor: pointer;
                        }
                    }

                    .wordBreak {
                        .fa {
                            position: absolute;
                            left: 0px;
                            top: 3px;
                            color: #333;
                            font-size: 16px;
                        }

                        .meetingURL {
                            font-size: 16px;
                            color: $misoBlue;
                            font-weight: bold;
                            padding-left: 25px;
                            width: calc(100% - 25px);

                            &:hover {
                                color: $misoGreen;
                                cursor: pointer;

                                .fa {
                                    color: $misoGreen;
                                }
                            }
                        }

                        .meetingItemURL {
                            font-size: 16px;
                            color: $misoBlue;
                            font-weight: bold;
                            width: calc(100% - 25px);

                            &:hover {
                                color: $misoGreen;
                                cursor: pointer;

                                .fa {
                                    color: $misoGreen;
                                }
                            }
                        }
                    }

                    .opacity {
                        opacity: .4;
                    }

                    .cancelled {
                        text-decoration: line-through;
                    }

                    .nonStrikeThrough {
                        text-decoration: none;
                    }

                    .unregister:hover {
                        cursor: pointer;
                    }

                    .eventDate {
                        margin-top: 0px;
                        display: inline;
                        font-size: 16px;
                        width: 75px;
                        color: #7f8f90;
                        float: left;
                        font-weight: bold;

                        .cancelled {
                            text-decoration: none;
                        }
                    }

                    .eventInfo {
                        display: inline;
                    }

                    .meetingDate {
                        margin-top: 0px;
                        font-size: 14px;
                        width: 75px;
                        color: #7f8f90;
                        font-weight: bold;

                        .cancelled {
                            text-decoration: none;
                        }
                    }

                    .meetingMaterialDate {
                        margin-top: 0px;
                        font-size: 14px;
                        width: 75px;
                        color: #7f8f90;
                        font-weight: bold;
                    }

                    @media screen and (max-width: 1199px) and (min-width: 768px) {
                        .eventDate {
                            display: flex;
                            margin-left: 0px;
                        }

                        .eventInfo {
                            display: block;

                            .eventURL {
                                margin-left: 10px;
                            }
                        }
                    }

                    .eventInfo {
                        .faIcons {
                            display: inline;
                            float: right;
                            width: auto;
                        }
                    }

                    .eventIcon {
                        text-align: center;
                        width: 30px;
                        border: 0px;

                        .opacity {
                            opacity: .4;
                        }

                        .register:hover,
                        .unregister:hover {
                            cursor: pointer;
                        }

                        .open {
                            opacity: 1;
                            color: $misoBlue;
                        }

                        .registered {
                            opacity: 1;
                            color: $misoGreen;
                        }

                        .cancelled {
                            opacity: .4;
                            color: $misoBlue;
                        }
                    }

                    .cancelled {
                        text-decoration: line-through;
                    }

                    .nonStrikeThrough {
                        text-decoration: none;
                    }

                    .register:hover,
                    .unregister:hover {
                        cursor: pointer;
                    }

                    .calButton {
                        margin-right: 15px;
                    }

                    .info {
                        display: block;
                        position: relative;
                        overflow: auto;

                        .wordBreak {
                            margin-right: 30px;
                            word-break: break-word;
                        }

                        .wordBreak.wb65 {
                            margin-right: 65px;
                        }
                    }

                    .follow {
                        position: absolute;
                        right: 0;
                        top: 0;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
/*---------//END EVENT LIST BLOCK STYLES --------------*/
/*---------//TARIFF TABS BLOCK STYLES --------------*/
.tariff-tabs {
    .tariff-search {
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid $misoBorderGray;
        margin-bottom: 20px;

/*        .legend {
            color: #000;
            font-size: 14px;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            text-align: right;
            margin-top: 20px;

            a {
                color: #000;
            }

            .popover {
                &.bottom {
                    margin-top: 10px;
                }
            }
        }*/
    }

    .tab-pane {
        .panel {
            border: none;
        }

        &.show {
            .panel {
                border: 1px solid #c2c2c3;
            }
        }
    }

    @media screen and (max-width: 991px) {
        .tab-content {
            border: none;
            padding: 0px;
        }

        .fade {
            opacity: 1;
            transition: initial;
        }

        .button-row {
            margin-top: 20px;
        }

        .tab-pane {
            .panel {
                border: 1px solid #c2c2c3;
            }
        }
    }

    @media screen and (min-width: 991px) {
        .tab-content {
            //padding: 10px;
            //padding: 10px;
        }

        .accordion-content .panel {
            //border-bottom: none;
        }

        .button-row {
            clear: both;
            float: right;
            margin-top: 52px;
        }
    }
}

.tariffDocList {
    div.tariffDocItem {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $misoBorderGray;
    }

    div.tariffFollowing {
        float: right;
        display: inline-block;
        font-size: 12px;
    }

    div.tariffDocTitle {
        font-size: 16px;
        color: $misoBlue;
        display: inline-block;
        font-weight: 400;
    }

    div.tariffDocDate {
        font-size: 16px;
        display: inline-block;
        font-style: italic;
    }

    div.tariffDocPropName {
        display: inline-block;
        font-weight: 400;
    }

    div.tariffDocPropVal {
        display: inline-block;
    }
}
/*---------//END TARIFF TABS BLOCK STYLES --------------*/
/*---------//EPIFORMS STYLES --------------*/
.EPiServerForms .Form__Element {
    margin: 0px 0px 10px 0px !important;
}

.Form__MainBody {
    select {
        display: block;
    }

    .FormTextbox .FormTextbox__Input {
        width: 100%;
    }

    label {
        display: block;
    }

    .btn[disabled],
    button[disabled] {
        cursor: default !important;
        opacity: .4 !important;
    }

    .Form__Element__Caption {
        /*display: block !important;*/
        margin-top: 10px;
    }

    .FormSelection {
        margin-bottom: 10px !important;
    }

    .FormCaptcha__Refresh,
    .FormResetButton {
        color: $misoLightGray;
        border-radius: 0px;
        border: 1px solid $misoLightGray;
        font-weight: bold;
        border-bottom: 3px solid $misoLightGray;
        text-transform: uppercase;
        padding: 10px;
        background-color: #fff;
        font-size: 14px !important;
        line-height: 1.42857;

        &:hover {
            border-bottom: 3px solid $misoGray;
            color: $misoGray;
            background-color: #e6e6e6;
        }

        &:focus {
            color: #333;
            background-color: #e6e6e6;
        }
    }

    .FormCaptcha__Refresh {
        display: block;
    }

    .FormCaptcha__Refresh:after {
        font-family: FontAwesome;
        content: "\f0a9";
        margin-left: 20px;
    }

    .FormResetButton:after {
        font-family: FontAwesome;
        content: "\f021";
        margin-left: 20px;
    }

    .FormSubmitButton {
        @extend .btn-primary;
        float: right;
        margin: 0px 0px 0px 30px !important;
    }

    .FormSubmitButton:after {
        font-family: FontAwesome;
        content: "\f058";
        margin-left: 40px;
    }

    .Form__NavigationBar__Action {
        /* @extend .btn-success;
               border-radius: 0px !important;
        border: 0 !important;
        font-weight: bold !important;
        border-bottom: 3px solid $misoLightGreen !important;
        border-top: 1px solid #81bc00 !important;
        text-transform: uppercase !important;
        padding: 10px !important;
        text-shadow: 1px 1px 2px #172731 !important;
        background-color: $misoGreen !important;
        color: #fff !important;
        font-size: 14px !important;
        float: left !important;
        background-image: none !important;
        width: auto !important;
        max-width: 100% !important;
        height: auto !important;

        &:hover {
            border-bottom: 3px solid $misoGreen !important;
            border-top: 1px solid #398439 !important;
            background-color: $misoDarkGreen !important;
        }

        &:focus {
            background-color: $misoGreen !important;
            border-top: 1px solid #81bc00 !important;
            border-bottom: 3px solid $misoLightGreen !important;
            outline-color: $misoLightGreen !important;
        }*/
        &.btnNext, &.btnPrev {
            border-radius: 4px !important;
            border: 2px solid transparent !important;
            color: white !important;
            font-weight: normal !important;
            padding: 10px 20px !important;
            float: left !important;
            height: auto !important;
            width: auto !important;
            background-image: none !important;
            white-space: nowrap !important;
            max-width: fit-content !important;
            background-color: $misoGreen;

            &:hover, &:focus {
                background-color: $misoDarkGreen;
                //text-decoration: underline;
            }
        }
    }

    .Form__NavigationBar__ProgressBar {
        display: none !important;
    }

    .btnNext, .btnPrev {
        float: left !important;
    }

    .btnNext {
    }

    .btnPrev {
        margin-right: 30px;
    }

    .btnPrev:before {
        font-family: FontAwesome;
        content: "\f0a8";
        margin-right: 20px;
    }

    .btnNext:after {
        font-family: FontAwesome;
        content: "\f0a9";
        margin-left: 20px;
    }

    button, .btn-default {
        margin-bottom: 0px;
    }

    .Form__Element .FormTextbox--Textarea .FormTextbox__Input {
        min-height: 275px;
        width: 100%
    }

    /*    .cancelButton {
        @extend .btn-default;
    }*/
    @media screen and (max-width: 768px) {
        .btnNext, .btnPrev {
            margin-bottom: 10px;
        }

        .btnNext {
            float: none !important;
        }

        .FormSubmitButton {
            margin: 10px 0px 10px 0px !important;
            display: block;
            float: none !important;
        }

        .btn-default {
            float: none !important;
        }
    }
}

#ui-datetimepicker-div {
    background-color: white;
    border: 2px solid $misoLightGray;
    border-radius: 4px;
    padding: 10px;
    min-width: 250px;

    .ui-datepicker-next {
        float: right;
    }

    .ui-datepicker-title {
        text-align: center;
        font-size: 24px;
    }

    .ui-datepicker-calendar {
        display: flex;
        flex-direction: column;
        width: 100%;

        thead tr th, tbody tr td {
            min-width:35px;
        }
    }
}
    /*---------//END EPIFORMS STYLES --------------*/
    /*---------//LEGEND BLOCK STYLES --------------*/
.legendblock {
    margin-bottom: 20px;

    .legend {
        color: #000;
        font-size: 14px;
        float: right;
        text-align: right;

        a {
            color: #000;
        }

        .popover {
            &.bottom {
                margin-top: 5px;
            }
        }

        .color-key {
            margin-bottom: 25px;
            position: relative;

            &:last-child {
                margin-bottom: 0px;
            }

            .color-key-icon {
                width: 30px;
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                height: 30px;
                margin: 0 auto;
                padding: 4px;
                border-radius: 50%;
            }

            .color-key-text {
                margin-left: 40px;
            }
        }
    }
}

    .legend {
        .legend-icon {
            width: 26px;
            height: 26px;
            padding: 2px 0px;
            border-radius: 50%;
            text-align: center;
            display: inline-block;
            margin-right: 5px;
            color: $white;
            /*&.pi { background-color: $misoOrange; }*/
            &.i {
                background-color: $misoDarkPurple;
            }

            &.b {
                background-color: $misoSHissueBI;
            }
            /*&.sd { background-color: $misoGray; }*/
            &.cd {
                background-color: $misoSHissueECD;
            }

            &.e {
                background-color: $misoSHissueECD;
            }

            &.v {
                background-color: $misoSHissueV;
            }

            &.f {
                background-color: $misoSHissueF;
            }

            &.d {
                background-color: $misoSHissueV;
            }

            &.ferc::before {
                display: block;
                content: "F";
                opacity: 1;
                background-color: $misoSHissueFerc;
                width: 24px;
                height: 24px;
                padding: 3px 1px;
                border-radius: 50%;
                text-align: center;
            }

            &.ferc {
                padding: 0px;
                display: inline-table;
                margin-right: -9px;
            }

            &.bpm {
                background-color: $misoDarkGray2;
                font-size: 11px;
                padding: 6px 0px;
            }

            &.ferc-border {
                padding: 7px;
                border: $misoSHissueFerc 4px solid;
                background-color: white;
                font-size: 2px;
            }

            &.bpm-border {
                padding: 7px;
                border: $misoDarkGray2 4px solid;
                background-color: white;
                font-size: 2px;
            }

            &.no-border {
                display: none;
            }

            &.ferc-with-bpm-border {
                padding: 7px;
                border: $misoSHissueFerc 3px solid;
                box-shadow: 0px 0px 0px 3px $misoDarkGray2;
                height: 21px;
                width: 21px;
                background-color: white;
                font-size: 1px;
                margin-left: 3px;
                margin-top: 2px;
                margin-right: 6px;
            }
        }

        .legend-row.ferc {
            height: 24px;
        }

        .legend-row.no-border {
            display: none;
        }
    }
    /*---------//END LEGEND BLOCK STYLES --------------*/
    /*---------//QBANK DOCUMENT BLOCK STYLES --------------*/
.qbank-document {
    position: relative;
    width: 100%;
    border: 2px solid $misoBorderGray;
    padding: 20px;

    .cta-body {
        word-wrap: break-word;

        .qbank-title {
            margin-bottom: 20px;

            &.followable {
                padding-right: 30px;
            }

            h4 {
                margin: 0px;
                line-height: 24px;
            }
        }
    }

    .cta-button {
        width: 100%;

        .btn {
            width: 100%;
            text-align: left;

            i {
                float: right;
            }
        }
    }

    .follow {
        position: absolute;
        top: 20px;
        right: 20px;

        h4 {
            margin: 0px;
            line-height: 24px;
        }
    }
}
/*---------//QBANK DOCUMENT BLOCK STYLES --------------*/
/*---------//DOCUMENT NAVIGATOR BLOCK STYLES --------------*/
.document-navigator {
    .docnav-tagcrumbs {
        .docnav-tagcrumb {
            display: inline-block;

            a {
                text-transform: none;
                font-size: 18px;
                margin-right: 10px;
            }
        }
    }

    .docnav-breadcrumbs {
        line-height: 14px;
    }
    
    .docnav-menu {
        margin-bottom: 20px;

        .docnav-menuitems {
            width: 100%;
            overflow: hidden;

            .docnav-menuitem:first-child {
                border-radius: 4px 4px 0px 0px;
            }

            .docnav-menuitem:last-child {
                border-radius: 0px 0px 4px 4px;
                border-bottom: 1px solid $misoBorderMedGray;
            }

            .docnav-menuitem {
                border: 1px solid $misoBorderMedGray;
                border-bottom: none;
                display: none;
                position: relative;

                &.current {
                    background-color: $misoDarkBlue;
                    color: $white;
                    border: 1px solid $misoDarkBlue;
                    border-bottom: none;

                    a {
                        color: white;
                    }
                }

                &.animate {
                    display: block;
                }

                &.active {
                    display: block;

                    &:hover {
                        background-color: $misoDarkBlue;
                        color: $white;
                        border: 1px solid $misoDarkBlue;
                        border-bottom: none;

                        a {
                            color: white;
                        }
                    }
                }

                a {
                    display: inline-block;
                    width: 100%;
                    padding: 10px 15px 10px 15px;
                    color: $misoEvilGray;
                }

                &.empty-item {
                    display: block;
                    height: 50px;

                    &:hover {
                        border: 1px solid $misoBorderMedGray;
                        background-color: $white !important;
                    }
                }
            }
        }
    }

    .docnav-navitem {
        .docnav-summary {
            font-size: 16px;
            margin-bottom: 20px;
        }

        .docnav-documents {
            font-size: 16px;
            line-height: 20px;

            .docnav-document {
                line-height: 24px;
                margin-bottom: 20px;
                position: relative;
                float: left;
                width: 100%;

                a.docnav-link {
                    width: 100%;
                    padding-right: 40px;

                    i.docnav-icon {
                        color: $black;
                        font-size: 24px;
                        line-height: 24px;
                    }

                    span.docnav-filename {
                        vertical-align: top;
                    }
                }

                span.follow {
                    position: absolute;
                    top: 0;
                    right: 0;

                    a.follow-link {
                        font-size: 24px;
                    }
                }
            }
        }

        table.docnav-metadata {
            font-size: 16px;
            margin-bottom: 10px;
            line-height: 26px;
            @extend .miso-striped-table;

            th {
                font-size: 18px;
                text-transform: capitalize;
                line-height: 26px;
            }

            th.colheader-sortable {

                @extend .sortableth;
            }

            tr {
                #opguidenumber {
                    width: 16%;
                }

                th:first-child {
                    width: 70%;
                }

                &.mobile-row {
                    display: none;
                }
            }

            @media screen and (max-width: 991px) {
                th {
                    text-align: center;
                    font-weight: bold;
                }

                tr {
                    display: none;
                }

                thead {
                    background-color: white;
                }

                tr.mobile-row {
                    display: table-row;
                    font-size: 16px;

                    ul.dtr-details {
                        list-style: none;
                        padding-left: 0px;

                        li {
                            span.dtr-title {
                                font-weight: bold;
                                width: 33%;
                                display: inline-block;
                                vertical-align: top;
                            }

                            span.dtr-data {
                                width: 66%;
                                display: inline-block;
                            }
                        }
                    }
                }

                tbody tr:nth-of-type(3n-1) {
                    background-color: #f9f9f9;
                }
            }
        }
    }
}
.miso-striped-table, miso-stripeless-table {
    th, td {
        padding: 5px;
    }

    th.colheader-sortable {
        @extend .sortableth;
    }
}
.miso-stripeless-table {
    thead {
        @extend .miso-stripeless-table-header;
    }
}
thead.miso-stripeless-table-header {
    background-color: $misoOpacityGray;
    font-weight: 900;
    text-transform: uppercase;
    color: $misoPrimaryBlack;
}
.miso-striped-table {
    thead {
        @extend .miso-striped-table-header;
    }

    tbody:nth-of-type(2n-1) {
        background-color: $misoOpacityGray;
    }
}
thead.miso-striped-table-header {
    font-weight: 900;
    text-transform: uppercase;
    color: $misoPrimaryBlack;
}
.fadeIn {
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
}

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

.slideIn {
    animation-name: slideIn;
    -webkit-animation-name: slideIn;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
}

.slideOut {
    animation-name: slideOut;
    -webkit-animation-name: slideOut;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
}

@keyframes slideOut {
    0% {
        transform: translateX(100%);
        background-color: $white !important;
    }

    15% {
        transform: translateX(95%);
        background-color: $white !important;
    }

    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slideOut {
    0% {
        transform: translateX(100%);
        background-color: $white !important;
    }

    15% {
        transform: translateX(95%);
        background-color: $white !important;
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-100%);
        background-color: $white !important;
    }

    15% {
        transform: translateX(-95%);
        background-color: $white !important;
    }

    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slideIn {
    0% {
        transform: translateX(-100%);
        background-color: $white !important;
    }

    15% {
        transform: translateX(-95%);
        background-color: $white !important;
    }

    100% {
        transform: translateX(0%);
            
    }
}
/*---------//END DOCUMENT NAVIGATOR BLOCK STYLES --------------*/
/*---------//COMMITTEE PAGE STYLES --------------*/

.linkList {
    .emptyList {
        color: #7f8f90;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0px;
    }

    .showMoreLink {
        font-size: 16px;
    }

    .showMoreLink:hover {
        color: $misoGreen;
        cursor: pointer;
    }

    .relatedLink {
        position: relative;
        padding: 10px 0px;
        border-bottom: 2px solid #cdd7d8;

        h4 {
            margin: 12px 0px;

            .wordBreak {
                margin-right: 30px;
                word-break: break-word;

                .fa {
                    position: absolute;
                    top: 3px;
                    left: 0px;
                    color: #333;
                    font-size: 16px;
                }

                a {
                    font-size: 16px;
                    display: inline-block;
                    padding-left: 25px;
                }
            }

            a {
                font-size: 16px;
            }

            a:hover {
                color: $misoGreen;
                cursor: pointer;

                .fa {
                    color: $misoGreen;
                }
            }
        }

        .relatedLinkDate {
            margin-top: 0px;
            padding-left: 20px;
            font-size: 14px;
            width: 75px;
            color: #7f8f90;
            font-weight: bold;
        }

        .follow {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .relatedLink:last-child {
        border-bottom: none;
        //text-decoration: none;
    }
}
/*  Override for whenever linklist is nested within and eventblock   */
.eventBlock .linkList {
    border: 0;
    padding: 0;
}
/*---------//END COMMITTEE PAGE STYLES --------------*/
/*---------//EventList Block on Profile STYLES --------------*/
.myEvents {
    float: left;
    width: 100%;
}

.alertRow {
    margin: 20px 0;
    text-align: center;
    color: $misoDarkBlue;
    background-color: #d9edf7;
    border: 1px solid $misoDarkBlue;
    padding: 15px 15px;

    .fa {
        font-size: 72px;
        vertical-align: middle;
    }
}
/*---------//END EventList Block on Profile STYLES --------------*/
/*---------//STAKEHOLDER FEEDBACK PAGE STYLES --------------*/
#stakeholdFeedback {
    .red.dueDate {
        color: #FF0000;
    }

    .dueDate {
        margin: 5px 0px 50px 0px;
        font-size: 16px;
        width: 100%;
        line-height: 18px;
    }

    .dueDate2 {
        margin-top: 0px;
        font-size: 16px;
        width: 75px;
        color: #7f8f90;
        font-weight: bold;
    }

    hr {
        border-top: 1px solid $misoDividerGrey;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .smallerHeader {
        color: $misoDarkGray2;
        font-size: 16px;
        margin: 5px 0px 5px;
    }

    .greyOut {
        opacity: .4;
    }

    .submission {
        .nameDate {
            margin-top: 0px;
            padding-left: 15px;
            font-size: 16px;
            width: 75px;
            color: #7f8f90;
            font-weight: bold;
        }
    }

    .dropdown {
        .form-control {
            border-radius: 4px;
            text-align: left;

            &:after {
                display: none;
            }

            .fa {
                margin-top: 2px;
            }
        }

        .dropdown-menu {
            background-color: $white;
            //width: 100%;
            min-width: 100%;
            //max-width: 100%;
            padding-left: 12px;

            .dropdown-item {
                width: 100%;
                text-align: left;
                min-width: 40px;
                max-width: 100%;
            }
        }

        .k-datepicker {
            padding: 0px;
            display: flex;
            height: 38px;
        }

        .k-input-button {
            padding: 0px 6px;
            background-color: transparent;
            border: none;
        }

        .k-icon:before {
            content: "\f073";
        }

        .k-icon {
            color: $misoDarkBlue;
            font: normal normal normal 14px/1 FontAwesome;
        }

        .k-datepicker {
            padding: 0px;
            display: flex;
            height: 38px;
        }

        .k-input-button {
            padding: 0px 6px;
            background-color: transparent;
            border: none;
        }

        .k-icon:before {
            content: "\f073";
        }

        .k-icon {
            color: $misoDarkBlue;
            font: normal normal normal 14px/1 FontAwesome;
        }
    }



    .k-datepicker {
        border: 1px solid #ccc;
        padding: 0px;
        display: flex;

        &:focus {
            box-shadow: none;
        }

        .k-input-inner {
            border: none;
            height: 36px;
        }

        .k-picker-wrap {
            border: 1px solid rgb(204, 204, 204);

            &:focus {
                border-color: #66afe9;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            }

            &:focus-within {
                border-color: #66afe9;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            }

            .k-input {
                height: 100%;
                box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.075);

                &:focus {
                    border-color: none;
                    box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.075);
                }
            }

            .k-select {
                border-left: none;
                box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.075);
            }
        }

        .k-state-border-down {
            border-color: #66afe9;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
        }
    }
}
/*---------//END STAKEHOLDER FEEDBACK PAGE STYLES --------------*/


/*---------//SEARCH PAGE STYLES --------------*/

.pagination > .active > a, .pagination > li > a:hover, .pagination > li > a:focus, .pagination > li.active > a:hover, .pagination > li.active > a:focus {
    background-color: $misoVeryLightGreen;
    border-color: $misoVeryLightGreen;
    color: $misoDarkGreen;
}

.search-navigator {
    .search-breadcrumbs {
        margin-bottom: 10px;

        .breadCrumbLink {
            color: $misoDarkGreen;
            background-color: $misoVeryLightGreen;
            border-radius: 4px;
            padding: 5px 8px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .docnav-menu {
        margin-bottom: 20px;
        display: block;

        .docnav-menuitems {
            width: 100%;
            overflow: hidden;

            .docnav-menuitem {
                border-bottom: 1px solid $misoBorderMedGray;
                border-top: none;
                display: none;
                position: relative;

                &.current {
                    background-color: $misoDarkBlue;
                    color: $white;
                    border: 1px solid #337ab7;
                    border-bottom: none;

                    a {
                        color: white;
                    }
                }

                &.animate {
                    display: block;
                }

                &.active {
                    display: block;

                    &:hover {
                        background-color: $misoDarkBlue;
                        color: $white;

                        .btn-link {
                            color: white;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                    border-radius: 0px 0px 4px 4px;
                }

                .btn-link {
                    display: inline-block;
                    width: 100%;
                    padding: 10px 35px 10px 15px;
                    color: $misoEvilGray;
                    transition-duration: 0s;

                    i {
                        line-height: 25px;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }
                }

                &.empty-item {
                    display: block;
                    height: 50px;

                    &:hover {
                        border: 1px solid $misoBorderMedGray;
                        background-color: $white !important;
                    }
                }

                input[type="checkbox"] {
                    margin: 4px 10px 0px 0px;
                }
            }

            .moreOrLess {
                background-color: $misoGray;
                color: $white;

                a {
                    color: white;
                }
            }
        }
    }

    .docnav-view {
        background-color: white;
        display: block;
    }

    .filterButton, .toggleButtons, .closeButton {
        display: none;
    }

    @media screen and (max-width: 767px) {
        .docnav-menu {
            background: white;
            margin-top: 0px;
            margin-bottom: 0px;
            display: none;
        }

        .toggleButtons,
        .filterButton {
            display: block;
        }

        .filterButton,
        .closeButton {
            width: 100%;
        }
    }

    .resultsRow {
        border-top: 1px solid $misoLightGray2;
        border-bottom: 1px solid $misoLightGray2;

        .sort-buttons {
            height: 35px;
        }

        .searchsort {
            color: $misoDarkBlue;
        }

        .pagination-controls {
            border-top: none;
            padding: 10px 0px;
            flex-direction: row-reverse;

            .rightSide {
                margin-right: 10px;
            }
        }
    }

}
/*---------//END SEARCH PAGE STYLES --------------*/

/*---------//GAS APP --------------*/

#gasNoticesTable {
    font-size: 16px;

    th, tr, td {
        font-size: 16px;
        padding:7px;
    }
}

#pipelineWarningsTable {
    border: none;
    font-size: 16px;

    th, tr, td {
        border: none;
        font-size: 16px;
    }

    thead {
        display: none;
    }
}
/*#criticalNoticesHeader {
    margin-bottom: 0px;
    position: absolute;
}

#criticalNoticesSearch {
    height: 36px;
    font-size: 16px;
    margin-bottom: 6px;
    width: 300px;
}

#gasNoticesTable_wrapper {
    .dataTables_length {
        margin-top: 10px;
        display: block;
        float: right;
        height: 35px;
        margin-bottom: 0px;
        padding-right: 15px;
    }

    .dataTables_paginate {
        width: 100%;
        float: left;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
    }
}

#gasNoticesTable {
    font-size: 16px;
}

#pipelineWarningsTable {
    border: none;

    th, tr, td {
        border: none;
    }

    thead {
        display: none;
    }
}


#pipelineWarningsTable.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: inherit;
}
#pipelineWarnings {
    .modal-body {
        text-align:left;
    }
}*/
/*---------//END GAS APP --------------*/

/*---------// GIQueue Modal --------------*/

#GIQueueModal {
    .modal-body {
        max-height:300px;
        overflow-y:auto;
        padding:10px 0px 10px 0px;
        height:auto;
        .linkList {
            padding:0px 10px 0px 10px;
            height:auto;
            .wordBreak {
                width:100%;
                display:inline-flex;
                .giQueueLink {
                    width:80%;
                    padding-right: 15px;
                }
                .relatedLinkDate {
                    width:20%;
                }
            }
        }
    }
}


#RegistrationModal.accessRequestModal {
    .modal-body {
        text-align: left;

        .modalrow {
            float: left;
            width: 100%;
            word-wrap: break-word;
        }

        .textleft {
            color: #333333;
        }
    }

    @media screen and (min-width: 768px) {
        .modal-dialog {
            width: 700px;
        }
    }

    @media screen and (min-width: 992px) {
        .modal-dialog {
            width: 900px;
        }
    }

    @media screen and (max-width: 767px) {
        .modalrow {
            margin-bottom: 10px;
        }

        .modalrowTop {
            display: none;
        }
    }
}
/*---------//END GIQueue Modal --------------*/
/*---------// News Overview Page --------------*/
#NewsOverview {
    .dropdownTitle {
        margin-right: 10px;
        font-size: 28px;
        line-height: 44px;
        flex-grow: 2;
    }

    .articleDate {
        font-size: 16px;
        color: #7f8f90;
        display: inline-block;
    }

    .accordion-link:hover {
        .articleDate {
            text-decoration: none;
        }
    }

    .headerSection {
        display: flex;
        margin-bottom: 20px;
    }

    .miso-dropdown {
        align-self: center;
    }

    .pager-text {
        display: none;
    }

    .pagination-count {
        margin-left: 0px;
        font-size: 16px;
        vertical-align: bottom;
    }

    .pagination-controls {
        height: 47px;
        margin-top: 10px;

        .pagination a {
            font-size: 16px;
        }
    }
}
/*---------// END News Overview Page --------------*/

/*---------// TinyMCE Table styles --------------*/
table.misoTable {
    border: 1px solid $misoDividerGrey;
    width: 100% !important;
    font-family: $misoDefaultFont;
    font-size: 18px;
    color: #000;
}

table.misoTable > thead {
    background-color: $misoLightTeal;
    font-weight: 700;
    line-height: 26px;
    font-size: 18px;
}

tbody tr:nth-child(even) {
    background-color: #FAFAFA;
}

table.misoTable td, table.misoTable td {
    padding: 5px;
    border: 1px solid $misoLightTeal;

    p {
        margin: 0px;
    }
}

table.misoTable thead td {
    font-size: 18px;
}

table.misoTable tbody td {
    font-size: 16px;
}

table.misoTable tr td:last-child {
    padding-right: 5px;
}

table.misoTable2 {
    border: 0px;
    width: 100% !important;
    box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    display: table-row;
}

table.misoTable2 > thead {
    background-color: #4b858e;
}

table.misoTable2 > thead > tr > th, table.misoTable2 > thead > tr > td, table.misoTable2 > tbody > tr > th, table.misoTable2 > tbody > tr > td, table.misoTable2 > tfoot > tr > th, table.misoTable2 > tfoot > tr > td {
    border: 0px;
}

table.misoTable2 > tbody > tr:nth-of-type(even) {
    background-color: #d9d8d6;
}

table.misoTable2 td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;

    p {
        margin: 0px;
    }
}

table.misoTable2 tr td:first-child {
    padding-left: 30px;
}

table.misoTable2 > thead > tr > th, table.misoTable2 > thead > tr > td {
    font-weight: bold;
    border-bottom-width: 2px;
    color: $white;
}

table.misoTable3 {
    border: 0px;
    width: 100% !important;
    box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    display: table-row;
}

table.misoTable3 > thead {
    background-color: #4b858e;
    width: 100%;
    display: inline-table;
}

table.misoTable3 tbody {
    max-height: 540px;
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
}

table.misoTable3 > thead > tr > th, table.misoTable3 > thead > tr > td, table.misoTable3 > tbody > tr > th, table.misoTable3 > tbody > tr > td, table.misoTable3 > tfoot > tr > th, table.misoTable3 > tfoot > tr > td {
    border: 0px;
}

table.misoTable3 > tbody > tr {
    width: calc(100% + 8px);
    display: inline-table;
}

table.misoTable3 {
    tbody::-webkit-scrollbar-track {
        display: none;
    }

    tbody::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    tbody::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #CCCCCC;
    }
}

table.misoTable3 > tbody > tr > td {
    /*display: inline-table;*/
    width: 20%;
}

table.misoTable3 td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;

    p {
        margin: 0px;
    }
}

table.misoTable3 tr td:first-child {
    padding-left: 30px;
    width: 25%;
}

table.misoTable3 tr td:last-child {
    padding-right: 8px;
}

table.misoTable3 > thead > tr > th, table.misoTable3 > thead > tr > td {
    font-weight: bold;
    border-bottom-width: 2px;
    color: $white;
    width: 20%;
}

table.misoTable3 tr {
    border-bottom: 1px solid #d9d8d6;
}

table.misoTable4 {
    border: 0px;
    width: 100% !important;
    box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    display: table-row;
}

table.misoTable4 > thead {
    background-color: #4b858e;
}

table.misoTable4 > thead > tr > th, table.misoTable4 > thead > tr > td, table.misoTable4 > tbody > tr > th, table.misoTable4 > tbody > tr > td, table.misoTable4 > tfoot > tr > th, table.misoTable4 > tfoot > tr > td {
    border: 0px;
}

table.misoTable4 td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;

    p {
        margin: 0px;
    }
}

table.misoTable4 tr td:first-child {
    padding-left: 30px;
}

table.misoTable4 > thead > tr > th, table.misoTable4 > thead > tr > td {
    font-weight: bold;
    border-bottom-width: 2px;
    color: $white;
}

table.misoTable4 tr {
    border-bottom: 1px solid #d9d8d6;
}

.tinymceFormElement {
    width: 100%;

    table.mceLayout {
        width: 100% !important;

        iframe {
            height: 200px !important;
        }
    }
}

.docnav-documents {
    clear:both;
    table.misoTable2 {
        border: 0px;
        width: 100% !important;
        box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        display: table;
    }

    table.misoTable2 > thead {
        background-color: #4b858e;
    }

    table.misoTable2 > thead > tr > th, table.misoTable2 > thead > tr > td, table.misoTable2 > tbody > tr > th, table.misoTable2 > tbody > tr > td, table.misoTable2 > tfoot > tr > th, table.misoTable2 > tfoot > tr > td {
        border: 0px;
    }

    table.misoTable2 > tbody:nth-of-type(even) > tr {
        background-color: #d9d8d6;
    }

    table.misoTable2 td {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 5px;

        p {
            margin: 0px;
        }
    }

    table.misoTable2 tr td:first-child, table.misoTable2 tr th:first-child {
        padding-left: 15px;
        position: relative;
    }

    table.misoTable2 > thead > tr > th, table.misoTable2 > thead > tr > td {
        font-weight: bold;
        border-bottom-width: 2px;
        color: $white;
    }

    table.misoTable4 {
        border: 0px;
        width: 100% !important;
        box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
        display: table;
    }

    table.misoTable4 > thead {
        background-color: #4b858e;
    }

    table.misoTable4 > thead > tr > th, table.misoTable4 > thead > tr > td, table.misoTable4 > tbody > tr > th, table.misoTable4 > tbody > tr > td, table.misoTable4 > tfoot > tr > th, table.misoTable4 > tfoot > tr > td {
        border: 0px;
    }

    table.misoTable4 td {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 5px;

        p {
            margin: 0px;
        }
    }

    table.misoTable4 tr td:first-child, table.misoTable4 tr th:first-child {
        padding-left: 15px;
    }

    table.misoTable4 > thead > tr > th, table.misoTable4 > thead > tr > td {
        font-weight: bold;
        border-bottom-width: 2px;
        color: $white;
    }

    table.misoTable4 tr {
        border-bottom: 1px solid #d9d8d6;
    }

    .tinymceFormElement {
        width: 100%;

        table.mceLayout {
            width: 100% !important;

            iframe {
                height: 200px !important;
            }
        }
    }
}
/*---------// END TinyMCE Table styles --------------*/

/*---------//Access Request Approval Block styles --------------*/
.accessrequestAccordionRow {
    width: 100%;
    display: inline-block;
    line-height: 1;
}
/*---------// END Access Request Approval Block styles --------------*/

/*---------//Snapshot Dashboard styles --------------*/
.outlook-container {
    display: grid;
    min-width: 175px !important;

    .outlook-container-title {
        font-size: 12px;
        color: $black;
        padding-left: 0px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;

        .outlook-title {
            border-radius: 4px 4px 0px 0px;
            border: 1px solid $misoLightGray2;
            border-bottom: none;
            padding: 5px 10px;
        }

        .update-time {
            padding-top: 3px;
            float: right;
            color: $black;
            font-size: 12px;
            border-radius: 4px 4px 0px 0px;
            display: inline;
            border: 1px solid $misoLightGray2;
            border-bottom: none;
            padding: 5px 10px;
        }
    }

    .snapShots {
        display: flex;
        flex-wrap: wrap;
    }

    .outlook-box {
        //margin-bottom: 10px;
        padding: 0px;
        display: inline-grid;

        .box {
            display: flex;
            align-content: space-between;
            flex-wrap: wrap;
            border: 1px solid $misoLightGray2;
        }

        .outlook-title {
            color: $misoGray;
            margin: 0px;
            padding: 0px 0px;
            z-index: 1;
            position: relative;
            font-size: 1em;
            font-weight: bold;
            display: block;
            text-transform: uppercase;
            color: $misoDarkGray2;
        }

        .box-title {
            font-family: $misoDefaultFont;
            font-size: 12px;
            border-bottom: none;
            padding: 5px 10px 0px;
            margin: 0px;
            order: 2;
        }

        .box-details {
            height: auto;
            font-size: 24px;
            font-weight: bold;
            padding: 0px 10px;
            width: 100%;

            .outlook-details {
                display: block;
                font-size: 1.25em;
                margin-top: 0px;
                font-weight: bold;
            }
        }

        .forecast .outlook-details {
            color: $misoBlue;
        }

        .current .outlook-details {
            color: $misoLightBlue;
        }

        .lmp .outlook-details {
            color: $misoGreen;
        }

        .nsi .outlook-details {
            color: $misoDarkGreen;
        }

        .not-defined .outlook-details {
            color: $misoGray;
        }
    }

    @media (max-width: 420px) {
        .outlook-container-title {
            font-size: 20px;

            .update-time {
                padding-top: 11px;
                font-size: 10px;
            }
        }

        .outlook-box {
            .outlook-title {
                font-size: 1.25em;
            }

            .box-title {
                height: 85px;
            }

            .box-details {
                .outlook-details {
                    font-size: 1.1em;
                    margin-top: 28px;
                }
            }
        }
    }
}
/*---------// END Snapshot Dashboard styles --------------*/
/*---------// Unlock Profile Block styles --------------*/
.unlockProfile {
    table.misoTable2 {
        display: table;
        box-shadow: none;
        border: 1px solid #7f8f90;

        tr td:first-child {
            padding-left: 10px;
        }

        td {
            padding-bottom: 7px;
            padding-top: 7px;
            border: 1px solid #7f8f90;
            border-bottom-width: 1px;
        }

        tbody tr:nth-of-type(odd) {
            background-color: #d9d8d6;
        }

        tbody tr:nth-of-type(even) {
            background-color: $white;
        }
    }

    .inlineRow {
        display: inline-block;
    }
}
/*---------// END Unlock Profile Block styles --------------*/
/*---------// Profile Data Modal styles --------------*/
#ProfileDataModal {
    &.modal {
        --bs-modal-width: unset;
    }

    .modal-dialog {
        width: 1200px;
    }

    .btn-success {
        color: $white;
    }

    .requested {
        color: $misoBlue;
        font-size: 13px;
        font-style: italic;
    }

    .rejected {
        color: red;
        font-size: 13px;
        font-style: italic;
    }

    .required {
        font-size: 13px;
        font-style: italic;
    }

    .asterick {
        color: #B2292E;
    }

    i.fa {
        font-size: 14px;
    }

    .modal-body {
        text-align: left;
        color: #333;
        background-color: $white;

        table.misoTable2 {
            display: table;
            box-shadow: none;
            border: 1px solid #7f8f90;

            tr td:first-child {
                padding-left: 10px;
            }

            td {
                padding-bottom: 7px;
                padding-top: 7px;
                border: 1px solid #7f8f90;
                border-bottom-width: 1px;
            }

            tbody tr:nth-of-type(odd) {
                background-color: #d9d8d6;
            }

            tbody tr:nth-of-type(even) {
                background-color: $white;
            }
        }
    }

    .accessApprovalArea {
        .tab-content-block {
            margin-bottom: 0px;
        }

        .borderArea {
            border: 2px solid #cdd7d8;
            padding: 10px;
        }
    }
}
/*---------// END Profile Data Modal styles --------------*/
/*---------// Profile Data Table styles --------------*/
#profileDataPage {
    float: left;
    width: 100%;

    .form-control {
        width: auto;
    }

    .searchArea {
        padding-left: 12px;
    }

    #mySearchInput {
        display: inline;
        width: 300px;
        margin-left: 5px;
    }

    #roleSearch {
        border: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        padding-left: 2px;

        input {
            margin-left: 10px !important;
        }
    }

    #tableHolder {
        width: 100%;
    }
}

#userRoleDataPage {
    #UserRoleData {
        font-size: 16px;
    }
}

#AllProfiles_wrapper,
#UserRoleData_wrapper,
#UsersinRoleTable_wrapper,
#UnconfirmedUsers_wrapper {
    .dataTables_filter {
        text-align: left;
        margin-bottom: 20px;

        label {
            font-weight: bold;
            font-size: 20px;
        }

        input {
            width: 300px;
        }
    }

    th {
        font-weight: bold;
        font-size: 14px;
        line-height: 1.2;
        padding: 5px 22px 5px 6px;

        &.sorting {
            position: relative;

            &:before, &:after {
                font: normal normal normal 10px/1 FontAwesome;
                right: 5px;
                float: right;
                color: $misoGray2;
                position: absolute;
                display: block;
                opacity: .6;
            }

            &:before {
                content: "\f062";
                bottom: 50%;
            }

            &:after {
                content: "\f063";
                top: 50%;
            }

            &_asc:before, &_desc:after {
                color: $misoGreen;
            }
        }
    }

    td {
        font-size: 14px;
        padding: 6px 5px;

        &.center-cell {
            text-align: center;
        }
    }

    .dataTables_length, .dataTables_paginate {
        display: inline-block;
    }

    .dataTables_paginate {
        float: right;
        width: auto;
        text-align: end;
        display: flex;
        border-top: none;
        padding-top: 0px;
        margin-bottom: 0px;

        span {
            display: block;
        }

        .paginate_button {
            height: 35px;
            width: 35px;
            margin: 0px;
            padding: 0px 5px;
            border: 1px solid $misoLightGray2;
            border-right: 0px;
            border-radius: 0px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            color: $misoDarkBlue !important;

            &.current, &:hover, &.current:hover, &:active {
                background: $misoVeryLightGreen;
                //color: $misoDarkBlue !important;
            }

            &.disabled {
                border-color: $misoLightGray2;

                &:hover, &:focus {
                    cursor: not-allowed;
                    border-color: $misoLightGray2;
                }
            }
        }

        .last {
            border-right: 1px solid $misoLightGray2;
            border-radius: 0px 4px 4px 0px;
        }

        .ellipsis {
            text-align: center;
        }

        .first {
            border-radius: 4px 0px 0px 4px;
        }

        .first, .last{
            display: table-cell;
        }
    }

    .dataTables_length {
        margin-top: 7px;
    }
}
/*---------// END Profile Data Table styles --------------*/
/*---------// Login Validation styles --------------*/
.validation-summary-errors {
    ul {
        list-style-type: none;
    }
}

.alert-info {
    background-color: $misoBackgroundBlue;
    color: $misoDarkBlue;
    border: none;
    
    p:last-child {
        margin-bottom: 0;
    }
}

.alert-success {
    background-color: $misoVeryLightGreen;
    color: $misoDarkGreen;
    border: none;

    p:last-child {
        margin-bottom: 0;
    }
}

.alert-danger {
    border: none;

    p:last-child {
        margin-bottom: 0;
    }
}
/*---------// END Login Validation styles --------------*/
/*---------// MISO Matters styles --------------*/
#MISOMattersBlock {
    .viewAll {
        font-size: 16px;
        padding: 8px 10px 6px;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        border: 1px solid $misoDarkBlue;

        .fa-chevron-right {
            font-size: 14px;
            padding-left: 10px;
            font-weight: normal;
        }
    }
}

.misoMattersArticle {
    .misoMattersHeaderImage {
        img {
            max-width: 100%;
            width: 100%;
        }
    }
}

/*---------// END MISO Matters styles --------------*/
/*---------// Twitter Block styles --------------*/
.twitterBlock {
    h2 {
        margin-bottom: 0px;
    }

    .tweetArea {
        .topArea {
            padding: 8px 0px;

            .topAreaText {
                font-weight: bold;
            }

            .followButton {
                font-weight: bold;
                font-size: 12px;
            }
        }

        .individualTweet {
            padding: 10px 8px;
            border-top: 2px solid $misoBorderGray;

            .tweetHeaderArea {
                padding-bottom: 10px;
                display: inline-flex;

                .tweetImage {
                    height: 40px;
                    width: 40px;
                    background-color: blue;

                    img {
                        height: 40px;
                        width: 40px;
                    }
                }

                .tweetHandleArea {
                    margin-left: 10px;

                    .twitterName {
                        font-weight: bold;
                        font-size: 16px;

                        a {
                            color: #333333;
                        }
                    }

                    .twitterHandle {
                        font-size: 14px;
                        color: $misoBorderGray;
                    }
                }
            }

            .bodyArea {
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
        }
    }
}
/*---------// END Twitter Block styles --------------*/

/*----------// START board of directors page //-------------*/
.BoardOfDirectorsPage {
    p {
        margin-bottom: 25px;
    }

    .thumbnailImage {
        img {
            max-height: 150px;
        }
    }

    .largeImage {
        img {
            width: 100%;
            max-width: 100%;
            border-radius: 4px;
        }
    }
}
/*----------// END board of directors page //-------------*/
/* Start of Login Page Styling */
//add loginPageContainer class up here
//probably move this
.textInput {
    border-radius: 4px;
    border: 1px solid $misoLightGray2;
    height: 44px;
    width: 100%;
    padding-left: 10px;
}

.accountContainer {
    width: 100%;
    margin-top: 30px;

    .profileDetailsTab {
        .changePasswordSection {
            background-color: $misoBackgroundBlue;
            border-radius: 4px;

            .btn-primary {
                width: 100%;
                justify-content: center;
            }
        }
    }

    .flexContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .fv-plugins-icon-container {
        position: relative;

        .fv-plugins-icon {
            position: absolute;
            top: 35px;
            right: 12px;
        }
    }

    .contentContainer {
        margin: auto;
        max-width: 500px;
        width: 100%;
        font-size: 16px;

        &.max-width-350 {
            max-width: 350px;
        }

        &.max-width-390 {
            max-width: 390px;
        }

        &.changePassword, &.resetPassword {
            margin-top: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            margin-right: 100px;
        }

        &.passwordRequirements {
            .blueHeader {
                background-color: $misoBackgroundBlue;
            }

            ul {
                margin-bottom: 0px;
            }
        }

        &.noMargin {
            margin: 0px;
        }

        &.createProfile {
            max-width: 1400px;

            .contactInformation {
                font-size: 24px;
                font-weight: bold;
                color: $misoDarkGray2;
            }

            .emailText {
                color: $misoCalendarGray;
            }
        }

        input[type=text], input[type=password] {
            border-radius: 4px;
            border: 1px solid $misoLightGray2;
            height: 44px;
            width: 100%;
        }

        .btn-primary {
            width: 100%;
            display: block;
        }
    }

    .hero.blue {
        font-size: 36px;
    }

    .copyright {
        font-size: 12px;
        margin-top: 100px;
    }

    .has-error input.form-control {
        border: 1px solid $misoRed;
    }

    .has-success input.form-control {
        border: 1px solid $misoGreen;
    }

    .form-control-feedback {
        top: 40px;
        right: 15px;
        font-size: 20px;
    }

    .field-validation-error {
        font-weight: bold;
        margin-top: 3px;
        display: block;
    }

    .border-btm-gray {
        border-bottom: 1px solid $misoGray;
    }

    @media screen and (max-width: 767px) {

        .flexContainer {
            flex-direction: column-reverse;

            .contentContainer {
                margin-left: auto;
                margin-right: auto;

                &.passwordRequirements {
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 30px;
                }
            }
        }

        .changePasswordSection {
            margin-top: 20px;
        }
    }
}
/*----------// END landing page //-------------*/


/*----------// Widgets //-------------*/

.highcharts-legend-item.highcharts-gauge-series {
    word-wrap:normal;
    white-space:normal;
    &:hover {
        cursor:default;
    }
}

.highcharts-legend-item.highcharts-gauge-series path {
    //display:none;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    d: path("M 6 6 A 6 6 360 1 1 4,5");
}

/*----------// END Widgets //-------------*/

/*----------// Multiselect //-------------*/
.multiselect {
    margin-bottom: 20px;
    border-radius: 8px;

    .multiselect-input-section {
        width: 96%;
        margin: 10px 20px 0 20px;
        border-radius: 8px;

        .multiselect-selected-items {
            width: 100%;
            display: flex;

            ul {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;
                padding-left: 0;

                input {
                    border: none;
                    outline: none;
                    width: 225px;
                    margin: 10px;
                }

                .multiselect-chip {
                    padding: 8px 5px;
                    color: #3b9fd6;
                    background-color: #ebf5fb;
                    border-radius: 5px;
                    margin: 5px;

                    &:hover {
                        background-color: #bbe3fc;
                        color: #247bab;
                    }

                    i.fa {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .multiselect-dropdown {
        width: 96%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: 1px solid #ccc;
        border-top: none;
        margin: 0 20px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow-y: scroll;
        height: 350px;

        .mutliselect-selection-item {
            width: 250px;
            max-height: 75px;
            padding: 5px 15px;
            cursor: pointer;

            &.selected {
                background-color: #ebf5fb;
                color: #3b9fd6;

                &:hover {
                    background-color: #bbe3fc;
                    color: #247bab;
                }
            }

            &.not-selected {
                background-color: transparent;

                &:hover {
                    background-color: #f2f4f5;
                }
            }
        }
    }
}
/*----------// END Multiselect //-------------*/

.k-scheduler-monthview .k-scheduler-table td:first-child,
.k-scheduler-monthview .k-scheduler-table td:last-child,
.k-scheduler-monthview .k-scheduler-table th:first-child,
.k-scheduler-monthview .k-scheduler-table th:last-child {
    width: 0;
    padding: 0;
    visibility: hidden;
    border: none;
}