// Remove bootstrap button styles
#exportButton,
#exportSomeButton {
    .btn-group {float: none; width: auto; margin-bottom: 0px;
        .no-btn, .no-btn:hover {
            color: #337ab7 !important; background-color: transparent; border: none !important; font-weight: normal !important; text-transform: none !important;
            padding:0px !important; margin:0px !important; font-size: inherit;  float: none; width: auto;
        }
    }
}


.heroButtons {
    .btn {
        margin-right: 10px;

        &.fullWidth {
            width: 100%;
            margin-top: 10px;
        }
    }
}    
@media screen and (max-width: 991px) {
    .heroButtons {
        display:flex;
        .btn {
            width:100%;
            font-size:16px;
        }
    }
}

.btn-link {
    color: $misoLinkBlue;
}
