.block {
    margin-bottom: 20px;
    &.contentbreakerblock {
        margin-bottom: 0px;
    }
    &.headerblock {
        margin-bottom:5px;
    }
}
.emsLink {
     padding:0px;
     font-size:inherit;
}
.headerBlock {
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    border: 1px solid $misoLightGray2;
    border-radius: 4px;

    .primaryText {
        font-weight: bold;
        font-size: 18px;
    }

    .secondaryText {
        padding-top: 5px;
        font-size: 12px;
    }

    &.blue-bg {
        background-color: $misoBackgroundBlue;
    }

    &.green-bg {
        background-color: $misoLightGreen;
    }

    &.orange-bg {
        background-color: $misoLightOrange3;
    }

    &.red-bg {
        background-color: $misoLightRed2;
    }

    &.widget-heading {
        border-bottom: 1px solid $misoLightGray2;
    }

    &.hero {
        border: none;
        padding: 0px 0px 10px;

        &.med {
            .primaryText {
                font-size: 28px;
                font-weight: 400;
            }
        }

        &:not(.med) {
            .primaryText {
                font-size: 36px;
                font-weight: 400;
            }
        }

        .secondaryText {
            font-size: 24px;
            font-weight: 400;
        }

        &.page {
            .primaryText {
                font-size: 48px;
            }

            .secondaryText {
                font-size: 36px;
            }
        }
    }


    &.sectionHeader {
        border: none;
        padding: 0px 0px 10px;

        .primaryText {
            font-size: 24px;
            font-weight: 400;
        }

        .secondaryText {
            font-size: 18px;
            font-weight: 400;
        }
    }
}

.block {
    & > .withBorder {
        border-radius: 4px;
        border: 1px solid $misoBorderGray;

        .headerBlock {
            border: none;
            border-radius: 0px;

            &.hero {
                padding-left: 15px;
                padding-right: 15px;
            }

            &.widget-heading {
                border-bottom: 1px solid $misoLightGray2;
            }
        }

        & > .blockBody {
            padding: 10px 15px 15px;
        }
    }

    .headerBlock {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .headerLink {
            font-size: 16px;
            text-decoration: none;
            font-weight: bold;
            white-space: nowrap;

            &:hover, &:focus {
                text-decoration: underline;
                text-underline-offset: 4px;
            }

            .fa {
                padding-left: 10px;
                font-size: 14px;
            }
        }

        .leftSide {
            flex-grow: 2;
        }
    }

    .headerBlock.hero, .headerBlock.sectionHeader {
        .rightSide a.headerLink {
            @extend .btn-default-lw;

            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}




/*----------- REACT HOC ----------------*/
.relatedpagesblock, meetinglistblock {
    .hocblockbase {
        .linkList {
            padding: 0px;
            border: none;
            margin-top: 0px;
        }
    }
}
.relateddocumentsblock, .meetingmaterialblock {
    .hocblockbase {
        .linkList {
            padding: 0px;
            border: none;
            margin-top: 0px;
        }
    }
}

.hocblockbase {
    display: block;
    clear: both;
    background-repeat: no-repeat;
    border-radius: 6px;

    &.header-blue {
        background-color: $misoBackgroundBlue;
    }

    &.header-green {
        background-color: $misoLightGreen;
    }

    &.header-red {
        background-color: $misoLightRed2;
    }

    &.header-orange {
        background-color: $misoLightOrange3;
    }

    &.header-grey {
        background-color: $misoLightGray2;
    }

    .relatedpagesblock {
        .linkList {
            padding: 0px;
            border: none;
            margin-top: 0px;
        }
    }

    &.withBorder {
        border-radius: 4px;
        border: 1px solid $misoDividerGrey;

        .reactBlockBody {
            padding: 10px 15px 5px;
        }
    }

    

    .inset {
        padding: 45px 25px 0px 25px;
    }

    .integratedHeader {
        padding: 10px 15px;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h4 {
            margin-top: 0px;
            margin-bottom: 0px;
            line-height: 24px;
        }

        h5 {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 0px;
            margin-top: 10px;
            text-transform: none;
        }

        &.blue-bg {
            background-color: $misoBackgroundBlue;
        }

        &.red {
            background-color: $misoLightRed2;
            color: $black;

            .btn-link {
                color: $black;

                &:hover {
                    background-color: red;
                }
            }
        }

        .rightSide, .leftSide {
            display: inline-block;
        }

        .rightSide {
            float: right;

            .btn-link {
                font-size: 16px;
                text-decoration: none;
                font-weight: bold;
                padding: 0px;
                margin-bottom: 2px;
            }
        }
    }

    .metaDataList {
        padding: 0px;
        border: none;
        margin-top: 0px;

        .metaData {
            padding: 15px;
            width: 100%;
            display: block;
            border-bottom: 1px solid $misoDividerGrey;
            font-size: 16px;

            h5 {
                margin-top: 0px;
            }

            p {
                margin-bottom: 0px;
            }

            p.cancelled {
                text-decoration: line-through;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .dropdown {
        .form-control {
            width: auto;
            min-width: 200px;
            font-size: 16px;
            border-radius: 4px;
            text-align: left;
            font-family: $misoDefaultFont;
            color: $misoPrimaryBlack;
            padding: 10px 12px;
            height: 42px;

            i {
                color: $misoDarkBlue;
                font-size: 12px;
                padding-top: 4px;
            }
        }

        .dropdown-item {
            padding: 5px 5px;
            text-align: left;
        }

        &.open > .dropdown-menu {
            display: inline-grid;
        }
    }

    .pageLoader {
        text-align: center;
        font-size: 32px;
        padding: 30px 0px;
        color: $misoDividerGrey;
        opacity: .5;

        .fa {
        }
    }
}

/*----------- END REACT HOC ----------------*/
/*----------- Headers ----------------*/

.body-container .hero-header .container-fluid {
    padding:0px;
}

.hero-header {
    position: relative;
    border-radius: 8px;
    align-items: center;

    &.noImage {
        .hero-container {
            padding-bottom: 0px;
        }
    }

    &.withBackgroundColor.noImage {
        padding: 0px 20px 20px;
    }

    &.withImage {
        display: flex;
        min-height: 300px;
        flex-direction: column;

        .hero-container {
            padding-bottom: 30px;
            flex: 1;
            display: flex;
        }
    }

    &.header-blue {
        background-color: $misoBackgroundBlue;
    }

    &.header-green {
        background-color: $misoLightGreen;
    }

    &.header-red {
        background-color: $misoLightRed2;
    }

    &.header-orange {
        background-color: $misoLightOrange3;
    }

    &.header-grey {
        background-color: $misoLightGray2;
    }

    &.insetleft {
        padding-left: 15px;

        @media screen and (max-width: 992px) {
            padding-left: 0px;
        }
    }

    &.insetright {
        padding-right: 15px;

        @media screen and (max-width: 992px) {
            padding-right: 0px;
        }
    }

    @media screen and (max-width: 992px) {

        &.fixBC {
            .top-bar-row {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    @media screen and (max-width: 768px) {

        &.fixBC {
            .top-bar-row {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }


    &.absoluteBreadcrumbs .miso-breadcrumbs {
        display: flex;
        height: 75px;
        margin: 0px 0px -75px 0px;
        z-index: 10;

        .crumbs {
            z-index: 10;
        }

        #FollowFlag {
            z-index: 10;
            position: relative;
            margin-right: 15px;
        }
        /*        @media screen and (max-width: 992px) {

            .crumbs {
                margin-left: 15px;
            }
        }*/
    }



    .hero-image {
        border-radius: 8px;
        overflow: hidden;
        min-height: 300px;
    }

    .hero-image.fullwidth {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: 0px;

        img {
            position: absolute;
            width: auto;
            max-width: none;
            min-height: 100%;
        }

        &.fullwidth {
            img {
                left: 50%;
                top: 50%;
                min-width: 100%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .halfhalf {
        /*        .textSection {
            padding-left:30px;
            padding-right:30px;
        }*/
        .textSection-imageleft {
            padding-left: 30px;
        }

        .textSection-imageright {
            padding-right: 30px;
        }
    }

    .textSection {
        align-self: center;
    }

    .halfhalf.imageright {
        direction: rtl;
        text-align: left;

        .textSection {
            direction: ltr;
        }
    }

    &.possibleGradient {
        .halfhalf.imageleft.halfhalf.imageright {
            padding: 20px 0px 20px 20px;
        }

        .halfhalf.imageleft .hero-image:after {
            background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 40%);
        }

        .halfhalf.imageright .hero-image:after {
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 40%);
        }
    }

    &.noGradientPossible {
        .halfhalf {
            padding: 30px 30px 0px 30px;

            picture {
                border-radius: 8px;
            }
        }
    }

    .halfhalf.imageleft .hero-image:after {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    .halfhalf.imageright .hero-image:after {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 992px) {
        &.imageleft, &.imageright {
            direction: rtl;
            height: auto;
        }

        .hero-image {
            min-height: 200px;

            &.fullwidth {
                img {
                    left: 50%;
                    top: 50%;
                    min-width: 100%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .textSection {
            margin-top: 0px;
        }

        &.possibleGradient {
            .halfhalf.imageleft.halfhalf.imageright {
                padding: 20px 0px 20px 20px;
            }

            .halfhalf.imageright .hero-image:after, .halfhalf.imageleft .hero-image:after {
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 40%);
            }
        }
    }

    .hero-image {
        &.imageleft, &.imageright {
            //border-radius: 8px;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
        }

        picture {
            display: block;
        }
    }

    video {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.extendedHeader {
    overflow: hidden;
    min-height: 30px;

    .hero-image.imageleft, .hero-image.imageright {
        border-radius: 0px 0px 8px 8px;

        img {
            border-radius: 0px 0px 8px 8px;
            height: 100%;
        }
    }

    .withImage {
        .textSection-imageright, .textSection-imageleft {
            margin-top: 75px;
        }
    }


    .hero-header {
        &.noImage {
            //display: flex;
        }

        &.withImageImage > .hero-container {
            min-height: 300px;
            display: flex;
        }

        h2 {
            font-size: 48px;
        }

        h3 {
            font-size: 36px;
        }

        h4 {
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 992px) {

    .extendedHeader .hero-header {
        margin-left: -.75rem;
        margin-right: -.75rem;

        .textSection {
            margin-top:0px;
            padding-top:0px;
        }
    }
    .hero-header {
        &.noGradientPossible .halfhalf {
            padding-bottom: 0px;
        }

        &.possibleGradient {
            .halfhalf.imageleft.halfhalf.imageright {
                padding: 20px 0px 20px 20px;
            }

            .halfhalf.imageright .hero-image:after, .halfhalf.imageleft .hero-image:after {
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 40%);
            }

            .textSection {
                padding-top: 0px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &.noGradientPossible {
            .textSection {
                padding-top: 20px;
                padding-left: 0px;
                padding-right: 0px;
            }

/*            &.withBackgroundColor.noImage {
                padding: 20px 20px 20px;
            }*/
        }

        .halfhalf.imageright {
            direction: ltr;
        }
    }
}


 /*----------- End Headers ----------------*/

/*----------- Meeting List Block ----------------*/

.eventlistblock, .meetinglistblock {
    .eventIcon.greyOut {
        opacity: 0.5;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    .infoBox:last-child {
        margin-bottom: 0px;
    }
}
    /*----------- End Meeting List Block ----------------*/
    /*----------- Editorial React Block ----------------*/
    .editorialComp {
        &.insetText {
            padding: 15px;
        }
    }
    /*-----------End Editorial React Block ----------------*/
    /*----------- Equal height Section Block ----------------*/
.section-block {
    .row {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .block {
            display: inline-grid;

            .blockBody {
                display: inline-grid;
                width: 100%;
                height: 100%;
            }
        }
    }
}
/*----------- END Equal height Section Block----------------*/
/*----------- InfoBox Block ----------------*/
.infoBox {
    border-radius: 4px;
    border: 1px solid $misoDividerGrey;
    margin-bottom: 10px;

    .info {
        padding: 10px;

        h5 {
            margin-top: 0px;
        }

        .infoItem {
            font-size: 16px;

            a {
                &.fs18 {
                    font-size: 18px;
                }
                i {
                    padding-right: 5px;
                }
            }
        }

        .inlineArea {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            &.fs16 {
                font-size:16px;
            }
            .rightSide {
                display: inline-flex;

                a {
                    color: $misoDarkBlue;
                }

                i.fa {
                    margin-right: 3px;
                }

                > :first-child {
                    margin-right: 10px;
                    border-right: 1px solid grey;
                    padding-right: 10px;
                }

                > :last-child {
                    border-right: none;
                    padding-right: 0px;
                    margin-right: 0px;
                }
            }
        }
    }
}
/*----------- END InfoBox Block ----------------*/

/*----------- CTA LINK Block----------------*/

.ctaLinkContainer {
    border-radius: 4px;
    overflow: hidden;
    height:100%;
    display:grid;
    img {
        width: 100%;
    }

    .ctaLink {
        width: 100%;
        display: flex;
        align-items: center;
        line-height: 1.2;
        min-height: 51px;
        padding: 4px 12px;
        height:max-content;
        &.withImage {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        .ctaPrimaryIcon {
            padding-right: 10px;
        }

        .ctaLinkPrimaryText {
            font-size: 16px;
            //breakpoint
            width: 100%;

            &.withImage {
                font-size: 24px;
                font-weight: 400;
                line-height: 36px;
            }
        }

        .ctaLinkLink {
            float: right;
            font-weight: bold;
            display: flex;
        }

        .ctaLinkLinkText {
            font-size: 16px;
            white-space: nowrap;
        }


        .ctaLinkIcon {
            padding-left: 10px;
            float: right;
        }
    }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
    .ctaLink {
        .ctaLinkPrimaryText {
            font-size: calc(13px + 0.239625vw);
        }
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .ctaLink {
        min-height: 58px;

        .ctaLinkPrimaryText {
            font-size: calc(11px + 0.299625vw);
        }
    }
}
/* cta sidebar  -----------------------------------------*/
@media screen and (max-width: 991px) {
    .calltoactionsidebar {
        .btn {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-right: 0px;
        }     
    }
}
@container (min-width: 500px) {

    .ctaSideBar .ctaButtonDiv .btn {
        width: auto;
    }
}

@media screen and (min-width: 992px) {
    .calltoactionsidebar {
        &.col-sm-4 {
            .btn {
                width: 100%;
                display: flex;
                margin-right: 0px;
            }
        }

        .ctaButtonDiv {
            container-type: inline-size;
        }


        .alignRightButtons {
            .cta-body {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .ctaTextDiv {
                    margin-right: 20px;

                    h3 {
                        display: inline-flex;
                        margin-bottom: 0px;
                        padding-bottom: 10px;
                    }

                    h4 {
                    }
                }

                .ctaButtonDiv {
                    display: flex;
                    flex-direction: column;
                    float: right;
                    container-type: normal;


                    .btn {
                        vertical-align: baseline;
                        margin-top: 10px;
                        margin-right: 0px;
                        white-space: nowrap;

                        &:first-child {
                            margin-top: 0px;
                        }

                        .fa-2x {
                            vertical-align: bottom;
                        }
                    }
                }
            }
        }
    }
}

.ctaSideBar {
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
    display: grid;
    align-content: space-between;

    h3 {
        margin-top: 12px;
        margin-bottom: 5px;
        font-weight: 700;
    }

    h4 {
        margin-top: 5px;
        color: black;
        font-weight: normal;
    }

    .ctaImage {
        width: 100%;
        height: auto;
        padding-top: 0px;
    }

    .btn {
        margin-top: 10px;
        font-family: $misoBold;
        font-weight: bold;
        align-items: center;
        margin-right: 15px;
        text-align: left;
        width:100%;
        .fa {
            padding-left: 15px;
            align-items: center;
        }

        .fa-2x {
            vertical-align: bottom;
        }
    }

    .cta-body {
        padding-bottom: 20px;
        padding-left: 15px;
        padding-top: 20px;
        padding-right: 15px;
        display: grid;

        .ctaLinkPrimaryText {
            font-weight: bold;
        }

        .ctaPrimaryIcon {
            color: $misoDarkBlue;
        }
    }
}

.ctaLinkContainer, .ctaSideBar {
    &.cta-blue {
        background-color: $misoBackgroundBlue;
        color: $misoDarkBlue;

        h3 {
            color: $misoDarkBlue;
        }
        .ctaPrimaryIcon {
            color: $misoDarkBlue;
        }
    }

    &.cta-grey {
        background-color: $misoOpacityGray;
        border: 1px solid $misoLightGray2;
        color: $misoDarkGray2;

        h3 {
            color: $misoDarkGray2;
        }

        .ctaLinkPrimaryText {
            font-weight: bold;
        }

        .ctaPrimaryIcon {
            color: $misoDarkGray2;
        }
    }

    &.cta-green {
        background-color: $misoLightGreen;
        color: white;
        /*border: 1px solid transparent;*/

        h3 {
            color: $misoDarkGreen;
        }

        .btn-primary {
            color: white;
            background-color: $misoOpacityDarkGreen2;

            &:hover {
                background-color: $misoDarkGreen;
                border-color: transparent;
            }
        }

        .btn-default {
            color: $misoDarkGreen;
            border: 2px solid $misoDarkGreen;
        }

        .ctaPrimaryIcon {
            color: $misoDarkGreen;
        }
    }

    &.cta-orange {
        background-color: $misoLightOrange3;
        color: white;
        /* border: 1px solid transparent;*/

        h3 {
            color: $misoDarkOrange2;
        }

        .btn-primary {
            color: white;
            background-color: $misoDarkOrange2;

            &:hover {
                background-color: $misoDarkOrange2;
                border-color: transparent;
            }
        }

        .btn-default {
            color: $misoDarkOrange2;
            border: 2px solid $misoDarkOrange2;
        }
    }


    &.cta-red {
        background-color: $misoLightRed2;
        border: 2px solid $misoRed2;

        h3 {
            color: $misoRed2;
        }

        .btn-primary {
            color: white;
            background-color: $misoRed2;

            &:hover {
                background-color: $misoDarkRed2;
                border-color: transparent;
            }
        }



        .btn-default {
            color: $misoRed2;
            border: 2px solid $misoRed2;
        }

        .ctaPrimaryIcon {
            color: $misoRed2;
        }
    }

    &.cta-light-blue {
        background-color: $misoOpacityGray;
        color: $misoDarkGray2;

        h3 {
            color: $misoLightGray2;
        }

        .ctaPrimaryIcon, {
            color: $misoPrimaryBlack;
        }

        .ctaLinkLinkText {
            color: $misoDarkBlue;
        }
    }

    &.cta-blue-disabled {
        background-color: $misoDisabledBlue;
        color: $misoDarkBlue;

        h3 {
            color: $misoDisabledBlue;
        }
    }

    &.cta-clear {
        color: $misoDarkBlue;
        border: 2px solid $misoDarkBlue;
        margin-top: 0px;

        h3 {
            color: $misoDarkBlue;
        }

        .ctaPrimaryIcon {
            color: $misoDarkBlue;
        }
    }

    &.cta-clearGB {
        color: $misoDarkBlue;
        border: 1px solid $misoDividerGrey;
        margin-top: 0px;

        h3 {
            color: $misoDarkBlue;
        }

        .ctaPrimaryIcon {
            color: $misoDarkBlue;
        }

        .ctaLinkIcon {
            color: $misoGreen;
        }

        .ctaLinkLinkText {
            color: $misoGreen;
        }
    }

    &.cta-clearDropShadow {
        color: $misoDarkBlue;
        border: 2px solid $misoDividerGrey;
        margin-top: 0px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

        h3 {
            color: $misoDarkBlue;
        }

        .ctaPrimaryIcon {
            color: $misoDarkBlue;
        }

        .ctaLinkIcon {
            color: $misoGreen;
        }

        .ctaLinkLinkText {
            color: $misoGreen;
        }
    }

    &.cta-clearGreenB {
        color: $misoDarkBlue;
        border: 2px solid $misoDarkGreen;
        margin-top: 0px;

        h3 {
            color: $misoDarkBlue;
        }

        .ctaPrimaryIcon {
            color: $misoDarkBlue;
        }

        .ctaLinkIcon {
            color: $misoDarkGreen;
        }

        .ctaLinkLinkText {
            color: $misoDarkGreen;
        }
    }
}
/*----------- END CTA LINK Block----------------*/

.simplecalltoactionblock {
    display: inline-grid;
}

.simpleCtaBlock {
    padding-top: 29px;
    padding-left: 29px;
    padding-right: 29px;
    padding-bottom: 34px;
    border-radius: 4px;
    height: 100%;
    min-height: 156px;
    border: 1px solid transparent;

    .sctaBody {
        height: 100%;
        display: inline-block;
        vertical-align: top;


        &.twoThirds {
            width: 66.6%;
            padding-right: 10px;
        }

        .linkTextDiv {
            height: 100%;
            word-break: break-word;
        }
    }

    .imgContainer {
        height: 100%;
        /*margin-left: 6px;*/
        display: inline-block;
        width: 33.3%;

        img {
            border-radius: 4px;
            max-width: 100%;
            max-height: 100%;
            display: inline-block;
        }
    }

    .sctaPrimaryText {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 29px;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        height: 100%;
    }

    .sctaLink {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $misoDarkBlue;
        text-decoration: none;
        transition: 900ms;

        i {
            font-size: 12px;
            padding-left: 10px;
        }

        &:hover, &:focus {
            color: $darkBlueHover;
            text-decoration: underline;
            text-underline-offset: 3px;
            transition: all 0.3s ease-in;
        }
    }

    &.scta-orange {
        background-color: $scta_LightOrange;
        color: $misoDarkOrange2;
    }

    &.scta-blue {
        background-color: $scta_LightBlue;
        color: $misoDarkBlue;
    }

    &.scta-green {
        background-color: $scta_LightGreen;
        color: $misoDarkGreen;
    }

    &.scta-clear {
        border-color: $misoDarkBlue;
        color: $misoDarkBlue;
    }

    &.scta-clearGreenB {
        border-color: $misoDarkGreen;
        color: $misoDarkBlue;
    }

    &.scta-clearGB {
        border-color: $misoDividerGrey;
        color: $misoDarkBlue;
    }

    &.scta-red {
        background-color: $misoLightRed2;
        color: $misoRed2;
    }

    &.scta-clearDropShadow {
        border: 2px solid $misoDividerGrey;
        margin-top: 0px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    }
}




.clickableImageContainer {
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    &:hover {
        .imageButton {
            transition-duration: 1000ms;
            transition-property: opacity;
            opacity: 1;
        }

        .clickableImage {
            //transition-duration: 1000ms;
            //transition-property: opacity;
            opacity: 0.5;

            &:hover {
                transition-duration: 1000ms;
                transition-property: opacity;
            }
        }
    }

    .clickableImage {
        width: 100%;
        height: auto;
    }



    .imageButton {
        opacity: 0;
        width: 155px;
        height: fit-content;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        transition-duration: 1000ms;
        transition-property: opacity;
        display:block;
    }

    &.noHover {
        pointer-events: none;
    }
}

.genericaggregatorblock {
    & > .withBorder > .blockBody {
        padding-bottom: 0px;
        .tab-content-block {
            margin-bottom:0px;
        }
    }
}
.aggregatorPageRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .aggregatorSection {
        margin-bottom: 20px;
    }
}
.infoBoxWithThumbnail {
    border-radius: 6px;
    box-shadow: 0 0 6px 0.5px rgba(0,0,0,0.25);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;

    .imageContainer {
        display: inline-block;
        padding-right: 15px;
        vertical-align: baseline;

        .imageLink {
            height: 125px;
            width: auto;
            overflow: hidden;

            img {
                border-radius: 4px;
                height:125px;
                max-height: 125px;
                min-width: 50px;
            }
        }
    }

    .infoContainer {
        display: flex;
        flex-direction: column;
        padding-top: 6px;

        h4 {
            font-weight: 700;
            font-size: 16px;
            margin-top: 0px;
            margin-bottom: 0px;
            padding-bottom: 5px;
        }

        .infoDescription {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $misoDarkGray2;
            margin-bottom: 0px;
        }

        .infoLinkArea {
            height: 100%;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: flex-end;

            a {
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
}
.infoBoxWithImageHeader {
    display: flex;
    flex-direction: column;
    border: 1px solid $misoBorderGray;
    border-radius: 8px;
    overflow: hidden;

    img {
        max-width: 100%;
        width: 100%;
    }

    .contentBody {
        padding: 15px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .description {
            max-height: 83px;
            overflow: hidden;
            padding-top: 10px;
            font-size: 16px;
            margin-bottom: 0px;

            p {
                margin-bottom: 0px;
            }
        }


        .contentFooterFlexContainer {
            display: inline-flex;
            align-items: flex-end;
            flex-grow: 4;

            .contentFooter {
                padding-top: 15px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 12px;

                .footerArea {

                    .personName {
                        color: $misoPrimaryBlack;
                    }

                    .date {
                        padding-top: 0px;
                        color: $misoDarkGray2;
                    }
                }

                .category {
                    color: $misoDarkGreen;
                    background-color: $misoVeryLightGreen;
                    border: none;
                    border-radius: 4px;
                    padding: 5px 8px 4px;
                }
            }
        }
    }
}

// -----------EditorialLinkList Styles-------------------------------------
.editorialLinkList {
    border-radius: 4px;
    border: 1px solid $misoLightGray2;
    height: 100%;
    display: grid;
    align-content: space-between;

    .topSection {
        padding-left: 15px;
        padding-right: 15px;

        h3 {
            margin-top: 10px;
        }

        &.header-blue {
            background-color: $misoBackgroundBlue;
        }

        &.header-green {
            background-color: $misoLightGreen;
        }

        &.header-orange {
            background-color: $misoLightOrange3;
        }

        &.header-red {
            background-color: $misoLightRed2;
        }

        &.header-grey {
            background-color: $misoLightGray2;
        }
    }


    .linkItem {
        padding-left: 15px;
        padding-right: 15px;
        border-top: 1px solid $misoLightGray2;
        height: 56px;
        display: flex;
        align-items: center;


        .noPadding {
            padding: 0px;

            &.date {
                margin-top: 0px;
            }
        }

        a {
            font-size: 16px;
        }
    }
}
// -------- END EditorialLinkList Styles

