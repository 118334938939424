footer {
	width: 100%;
	padding: 35px 8px 20px 8px;
	margin-top: 20px;
	background-color: $misoBlue;
	border-top: 5px solid $misoBlue;
	color: $white;
	line-height: 40px;
	//position: absolute;
	//bottom: 0;

	@media only screen and (max-width: 992px) {
		.logoAndMediaContainer {
			padding-bottom: 35px;
		}

		.copyright {
			text-align: center;
		}
	}

	.logoAndMediaLinks {

		.misoLogoDiv {
			padding-bottom: 60px;

			@media only screen and (max-width: 992px) {
				padding-bottom: 0px;
			}


			.misoLogo {
				height: 68px;
			}
		}
	}

	.socialIcon {
		padding-right: 30px;
		margin-top: 18px;
	}

	.col-md-2 {
		margin-bottom: 40px;

		&.col-md-offset-2 {
			margin-bottom: 0px;
		}

		a {
			font-size: 16px;
			display: table;
			text-underline-offset: 5px;


			&:hover, &:focus {
				text-underline-offset: 5px;
				transition: 500ms;
			}
		}
	}

	.twitterX {
		fill: white;
		vertical-align: top;
		text-decoration: none !important;

		:hover {
			fill: #042c3f;
			text-decoration: none !important;
		}

		svg {
			width: 60px;
		}
	}

	h3 {
		margin-top: 0px;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 0px;
	}

	a {
		color: $white;
		text-decoration: underline 0.01em rgba(0,0,0,0);
		transition: text-decoration-color 500ms;

		&:hover {
			color: $white;
		}

		&:focus {
			outline: none;
			color: #fff;
			text-decoration: none;
		}
	}
}
