#skipToLinks {
    display:none;
}


.header-fixed {
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 100000;
    .navbar-alert {
        background-color: $misoDarkOrange2;
        color: $white;
        .global-alert-body p:last-child {
            margin-bottom: 0; 
        }
        p > a {
            color: $white;
        }
    }
}

.header-scrolling {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
    margin: auto;
    z-index: 9000;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
}

header {
    .util-nav {
        
        .alertCircle {
            color: $misoGreen;
        }
        .badge {
            padding: 3px 5px;
            font-size: 9px;
            background-color: $misoRed;
            border-radius: 50%;
            margin-left: -6px;
            margin-top: -15px;
            position: relative;
        }

        
    }
}

.follow-flag { 
	.btn-small { float: right; }
	.fa { color:$white; }    
}

.miso-breadcrumbs {
    background: none;
    list-style-type: none;
    font-size: 14px;
    padding: 0px;
    color: $misoDarkGray2;
    z-index: 100;
    margin: 0px 0px -10px 0px;

    .top-bar-row {
        width: 100%;
        display: flex;
        padding-top: 30px;
        margin-bottom: 15px;
        justify-content: space-between;

        #FollowFlag {
            float: right;

            .btn-default.follow-link {
                background-color: white;
            }
        }
    }

    .crumbs {
        float: left;
        align-self: end;
        display: flex;
        align-items: center;

        .divider {
            color: $misoDarkGray2;
            padding: 2px 12px 0px;
            font-size: 9px;
        }

        .breadCrumbLink {
            color: $misoDarkGray2;
            text-decoration: none;
            line-height: 17px;

            &:hover {
                text-decoration: underline;
            }
        }


        .breadCrumbItem {
            color: $misoDarkGray2;
        }
    }

/*    @media screen and (max-width: 991px) {
        .top-bar-row {
            padding-left:15px;
            padding-right:15px;
        }
    }*/

    @media screen and (max-width: 767px) {
        .top-bar-row {
            width: 100%;
            flex-direction: column;
            margin-left: 15px;

            #FollowFlag {
                float: left;
                margin-top: 5px;
            }
        }

        .crumbs {
            display: block;
            width: 100%;
        }
    }
}
/*@media screen and (max-width: 767px) {
    .miso-breadcrumbs {
        position:absolute;
    }


}
*/

.editBar {

    background: #444a4c;
    color: #fff;
    padding: 8px 8px 6px 10px;
    font-size: 14px;
    height:36px;
    margin-bottom: 20px;
    .epi-editContainer {
        float:none;
        width:auto;
        display: inline;
    }
    .fa {
        margin-right:10px;
        color:$misoLightGray;
    }
}