.row-even {

 [class*=' col-']  div {
    min-height:300px;
}

}
/*---------TAB STYLES -------------*/
.tab-content-block {
    margin-bottom: 10px;
}
.navbar-collapse {
    margin-bottom: 0px;
}

.navbar-collapse, .tab-content-block {
    .nav-tabs {
        padding-left: 0px;
        padding-right: 0px;
        border-bottom: 1px solid $misoDividerGrey;
    }
    .dt-buttons{
        display:flex;
    }

    .navbar-nav, .nav-tabs {
        .nav-item {
            background-color: $white;
            margin-bottom: 0px;
            border-bottom: 0px solid transparent;
        }

        .nav-link {
            position: relative;
            display: block;
            font-size: 16px;
            line-height: 24px;
            color: $misoPrimaryBlack;
            border: none;
            /*padding: 10px 15px;*/

            &:hover, &:focus {
                color: $misoDarkBlue;
                background-color: $white;
                border: none;
                cursor: pointer;
                text-decoration: none;

                &:after {
                    content: "";
                    position: absolute;
                    border-top: 6px solid $misoGreen;
                    border-radius: 4px 4px 0px 0px;
                    width: 100%;
                    bottom: 0px;
                    left: 0px;
                }
            }

            &.active {
                //color: $misoDarkBlue;
                font-weight: bold;

                &:after {
                    content: "";
                    position: absolute;
                    border-top: 6px solid $misoGreen;
                    border-radius: 4px 4px 0px 0px;
                    width: 100%;
                    bottom: 0px;
                    left: 0px;
                }
            }
        }
    }

    .tab-content {
        padding-top: 10px;
    }
}
@media screen and (min-width: 992px) {
    .nav.navbar-nav {
        padding-left: 0px;
        padding-right: 0px;

        > li, > .dropdown-toggle {
            background-color: $white;
            margin-bottom: 0px;
            border-bottom: 0px solid transparent;

            > a {
                border: none;
            }

            > a:hover, > a.show {
                color: $misoDarkBlue;
                font-weight: bold;

                &:after {
                    content: "";
                    position: absolute;
                    border-top: 6px solid $misoGreen;
                    border-radius: 4px 4px 0px 0px;
                    width: 100%;
                    bottom: 0px;
                    left: 0px;
                }
            }
        }

        > .active, > .open {
            > a {
                color: $misoDarkBlue;
                font-weight: bold;

                &:after {
                    content: "";
                    position: absolute;
                    border-top: 6px solid $misoGreen;
                    border-radius: 4px 4px 0px 0px;
                    width: 100%;
                    bottom: 0px;
                    left: 0px;
                }
            }
        }
    }
}

    /*---------END TAB STYLES -------------*/

/*--------MISO Dropdown------------*/
.miso-dropdown {
    display: inline-block;

    .dropdown {
        .form-control {
            width: auto;
            min-width: 100px;
            font-size: 16px;
            border-radius: 4px;
            text-align: left;
            font-family: $misoDefaultFont;
            color: $misoPrimaryBlack;
            padding: 10px 12px;
            height: 42px;

            i {
                color: $misoDarkBlue;
                font-size: 12px;
                padding-top: 4px;
            }
        }

        .dropdown-menu {
            border-radius: 4px;
            min-width: auto;
            width: 100%;
        }

        .dropdown-item {
            padding: 5px 10px;
            text-align: left;
            font-size: 16px;
            font-family: $misoDefaultFont;
            min-width: auto;
        }

        &.open > .dropdown-menu {
            display: inline-grid;
        }
    }
}

/*--------END MISO Dropdown------------*/


/*---------//GLOBAL MODAL STYLES --------------*/
.modal {
    transition: all 0.5s;
    z-index: 9002;


    .modal-content {
        top: 150px;
        border: 2px solid $misoDarkBlue;
    }

    .modal-header {
        background-color: $misoBackgroundBlue;
    }

    &.show {
        display: block;
    }
}

/*---------//END GLOBAL MODAL STYLES --------------*/

/*---------//GLOBAL Popover STYLES --------------*/
.popover {
    max-width: 675px;
    width: 100%;
    top: 120px;
    z-index: 100001;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    .k-event {
        position: relative;
        border-left-width: 8px;
        margin-bottom: 10px;

        .event {
            min-height: 25px;
        }

        .event-type {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 100%;
            opacity: 0.25;
        }

        .event-title {
            margin-left: 10px;
        }
    }
}
.widgetPopover {
    max-width:350px;
}
.legend-popover {
    display: inline-block;

    &:hover, &:focus {
        cursor: pointer;

    }

}
.popover-body {
    .legend-icon {
        display: inline-block;
        padding: 5px;
        vertical-align: middle;
        @extend .phase-icon;

        &.ferc-border, &.ferc-with-bpm-border, &.bpm-border, &.other-border, &.no-border {
            color: transparent;
            font-size: 0px;
        }
    }
}
/*---------//END GLOBAL Popover STYLES --------------*/
/*---------//TOASTR STYLES -------------*/
#toast-container {
    .toast {
        background-color: white !important;
        background-image: none !important;
        border: 2px solid $misoDarkBlue !important;
        border-radius: 4px;
        opacity: 1;
        color: $misoDarkBlue !important;
        padding: 25px 15px 25px 75px;
        right: -100%;
        width: 350px;
        top: 55px;
        position:absolute;
        z-index: 9003;

        &:before {
            font-family: FontAwesome;
            position: absolute;
            left: 0;
            top: 0px;
            width: 75px;
            text-align: center;
        }

        .toast-title {
        }

        .toast-message {
        }

        .toast-progress {
            background-color: $misoGreen;
            opacity: 1;
        }

        .toast-close-button {
            color: $misoDarkBlue;
            border: none;
            background: none;
            float: right;
            font-size: 20px;
            font-weight: bolder;
            position: absolute;
            right: 5px;
            top: 0;

            &:hover {
                color: $darkBlueHover;
            }
        }

        &.toast-error {
            background-image: none !important;

            &:before {
                top: calc(50% - 37px);
                font-size: 3em;
                content: "\f00d";
            }
        }

        &.toast-info {
            background-image: none !important;

            &:before {
                top: calc(50% - 43px);
                font-size: 3.5em;
                content: "\f129";
            }
        }

        &.toast-success {
            background-image: none !important;

            &:before {
                top: calc(50% - 37px);
                font-size: 3em;
                content: "\f00c";
            }
        }

        &.toast-warning {
            background-image: none !important;

            &:before {
                top: calc(50% - 43px);
                font-size: 3.5em;
                content: "\f12a";
            }
        }
    }
}
/*---------//END TOASTR STYLES -------------*/


/*--------------follow Icon -----------*/
.follow-link{
    padding:0px;
    //border-radius: 0px !important;
    //line-height: normal !important;
    &:hover, &:focus {
        text-decoration: none;
    }
}

/*--------------END follow Icon -----------*/

/*---------EPI FORMS ---------------*/

.EPiServerForms .Form__Element .Form__Element__Caption {
    font-size:24px;
}
.EPiServerForms .ValidationRequired legend.Form__Element__Caption:after, .EPiServerForms .ValidationRequired label.Form__Element__Caption:after {
    content: " *";
    color: $misoRed;
}

/*--------- END EPI FORMS ---------------*/